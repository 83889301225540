import styled from 'styled-components'

export const StyledMainHeader = styled.div`
  align-items: center;
  backdrop-filter: blur(24px);
  background-color: ${(props) =>
    props.theme.tokens.colors.surface['surface-modal-blur']};
  box-shadow: inset 0 -1px 0 ${(props) => props.theme.tokens.colors.border['border-header']};
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr auto;
  padding: 8px 16px;
`

export const StyledMainHeaderLeft = styled.div`
  align-items: center;
  display: grid;
  gap: 48px;
  grid-template-columns: auto minmax(0, 1fr);
`
