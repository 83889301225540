import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { StyledTabItemProps } from './tabs.types'

export const TabsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    height: 28px;
    overflow: hidden;
  `}
`

export const StyledTabItem = styled.div<StyledTabItemProps>`
  ${typography['Body / 12 BOLD']}
  cursor: pointer;
  display: flex;
  transition: background-color 0.25s ease, color 0.25s ease;

  svg {
    height: 20px;
  }

  white-space: nowrap;

  ${({ theme, type }) => css`
    background-color: transparent;
    border-radius: ${theme.tokens.alias.space['space-xxs-6']}px;
    color: ${theme.tokens.colors.text['text-secondary-3']};
    svg {
      fill: ${theme.tokens.colors.icon['icon-secondary-2']};
    }

    ${type === 'text' &&
    css`
      padding: ${theme.tokens.alias.space['space-xxs-6']}px
        ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xxs-6']}px
        ${theme.tokens.alias.space['space-xs-8']}px;
    `}

    ${type === 'logo' &&
    css`
      padding: ${theme.tokens.alias.space['space-xxxs-4']}px
        ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xxxs-4']}px
        ${theme.tokens.alias.space['space-xs-8']}px;
    `}

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-hover']};
      color: ${theme.tokens.colors.text['text-secondary']};
      svg {
        fill: ${theme.tokens.colors.icon['icon-primary-2']};
      }
    }
  `}
`

export const AnchorWrapper = styled.div`
  position: relative;
`

export const VisibleContainer = styled(TabsContainer)`
  left: 0;
  overflow: visible;
  position: absolute;
  top: 0;
`

export const HiddenContainer = styled(TabsContainer)`
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  visibility: hidden;
`

export const OverflowButton = styled.div`
  cursor: pointer;
  position: relative;
  ${({ theme }) => css`
    background-color: transparent;
    border-radius: ${theme.tokens.alias.space['space-xxs-6']}px;
    color: ${theme.tokens.colors.text['text-secondary-3']};
    svg {
      fill: ${theme.tokens.colors.icon['icon-secondary-2']};
    }
    padding: 2px 6px;

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-hover']};
      color: ${theme.tokens.colors.text['text-secondary']};
      svg {
        fill: ${theme.tokens.colors.icon['icon-primary-2']};
      }
    }
  `}
`

export const DropdownWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  z-index: 1000;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-active']};
    box-shadow: ${theme.tokens.shadows.s};
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    outline-offset: -1px;
  `}
`

export const StyledOldWebsiteLinkWrapper = styled.div`
  align-items: center;
  column-gap: 12px;
  display: flex;
`

export const StyledOldWebsiteLink = styled.a(
  ({ theme }) => css`
    ${typography['Body / 12 Medium']}
    color: ${theme.tokens.colors.text['text-description']};
    width: 76px;

    &:hover {
      color: ${theme.tokens.colors.text['text-secondary-3']};
    }
  `
)

export const Divider = styled.div(
  ({ theme }) => `
  background-color: ${theme.tokens.colors.divider['divider-primary']};
  width: 1px;
  height: 16px;
`
)
