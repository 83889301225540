import React, { FC, useCallback } from 'react'

import { StyledOutcomesGroupsTab } from './outcomes-filter-tab.styled'
import { IOutcomesGroupsTabProps } from './outcomes-filter-tab.types'

export const OutcomesGroupsTab: FC<IOutcomesGroupsTabProps> = ({
  item,
  value,
  onChange
}) => {
  const handleItemClick = useCallback(
    (value: string) => () => {
      onChange(value)
    },
    [onChange]
  )

  return (
    <StyledOutcomesGroupsTab
      isActive={value === item.value}
      onClick={handleItemClick(item.value)}
    >
      <span>{item.label}</span>
    </StyledOutcomesGroupsTab>
  )
}
