import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import { selectBetsHistoryPageValues } from 'astra-core/containers/BetsHistoryProvider'

import { IconTimeFilled40 } from 'shared/ui/Icon/General/IconTimeFilled40'
import {
  formatBetsHistoryDate,
  isTodayOrYesterday
} from 'shared/lib/format/bets-history-date'
import { LegacyBet } from 'entities/legacy-api/lib/types'
import { BetHistoryCardLegacy } from 'features/bet-history-card-legacy'
import { BetHistoryCard } from 'features/bet-history-card'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  EmptyContent,
  EmptyDescription,
  EmptyText,
  EmptyTitle,
  EmptyWrapper,
  ListWrapper,
  StyledBetsDate,
  StyledListItem
} from './list.styled'
import { GroupedByDay, HistoryListProps } from './list.types'

const Empty = () => {
  const { t } = useTranslation()
  return (
    <EmptyWrapper>
      <EmptyContent>
        <IconTimeFilled40 colorToken="icon-secondary-3" size={40} />
        <EmptyText>
          <EmptyTitle>{t('history bets is empty')}</EmptyTitle>
          <EmptyDescription>
            {t('the bets will be displayed here')}
          </EmptyDescription>
        </EmptyText>
      </EmptyContent>
    </EmptyWrapper>
  )
}

export const HistoryListLegacy: FC<HistoryListProps> = ({ bets }) => {
  const { t } = useTranslation()

  const betsListByDay: GroupedByDay[] = useMemo(() => {
    const groupedByDay: Record<string, GroupedByDay> = {}

    bets.forEach((obj: LegacyBet) => {
      const date = new Date(obj.dttm * 1000)
      const dayKey = formatBetsHistoryDate(date)

      if (!groupedByDay[dayKey]) {
        groupedByDay[dayKey] = {
          date: dayKey,
          bets: [],
          timestamp: date
        }
      }

      groupedByDay[dayKey].bets.push(obj)
    })

    return Object.values(groupedByDay)
  }, [bets])

  if (!bets.length) {
    return <Empty />
  }

  return (
    <ListWrapper>
      {betsListByDay.map(({ date, bets, timestamp }) => (
        <StyledListItem key={date}>
          <StyledBetsDate>
            <span>{isTodayOrYesterday(timestamp, t)}</span>
            {date}
          </StyledBetsDate>
          {bets.map((bet) => (
            <BetCardWrapper bet={bet} key={bet.bet_id} />
          ))}
        </StyledListItem>
      ))}
    </ListWrapper>
  )
}

const BetCardWrapper: FC<{ bet: LegacyBet }> = ({ bet }) => {
  const [platform, id] = bet.cardnumber.split(' ')
  const betsListTableData = useAppSelector(selectBetsHistoryPageValues)
  const platformBetsHistoryEntries = useMemo(
    () => keyBy(betsListTableData, 'id'),
    [betsListTableData]
  )

  const isPlatformBet = platform === 'Astrabet' && !!id

  return isPlatformBet && platformBetsHistoryEntries[id] ? (
    <BetHistoryCard item={platformBetsHistoryEntries[id]} number={bet.bet_id} />
  ) : (
    <BetHistoryCardLegacy item={bet} />
  )
}
