import styled from 'styled-components'

import { DataListWithPagination } from 'shared/ui/DataList'

export const StyledHistory = styled.section`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
`

export const StyledColumn = styled.div`
  justify-self: center;
  max-width: 1640px;
  width: 100%;
`

export const StyledListWrapper = styled(DataListWithPagination)`
  height: 100%;
  justify-self: center;
  max-width: 1640px;
  overflow: unset;
  width: 100%;
`
