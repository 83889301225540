import { css } from 'styled-components'

export const typography = {
  'Title / 24 ExtraBold': css`
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-transform: none;
  `,
  'Title / 24 Bold': css`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-transform: none;
  `,
  'Title / 20 ExtraBold': css`
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-transform: none;
  `,
  'Title / 20 Bold': css`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: none;
  `,
  'Title / 18 Bold': css`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: none;
  `,
  'Title / 18 ExtraBold': css`
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 16 ExtraBold': css`
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 16 Bold': css`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 16 SemiBold': css`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 14 ExtraBold': css`
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 14 Bold': css`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 14 SemiBold': css`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 14 Medium': css`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 14 Regular': css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
  `,
  'Body / 12 EXTRABOLD': css`
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    text-transform: uppercase;
  `,
  'Body / 12 ExtraBold': css`
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    text-transform: none;
  `,
  'Body / 12 BOLD': css`
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  `,
  'Body / 12 Bold': css`
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: none;
  `,
  'Body / 12 SemiBold': css`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: none;
  `,
  'Body / 12 Medium': css`
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;
  `,
  'Body / 12 Regular': css`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 11 EXTRABOLD': css`
    font-size: 11px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-transform: uppercase;
  `,
  'Hint / 11 ExtraBold': css`
    font-size: 11px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 11 BOLD': css`
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
  `,
  'Hint / 11 Bold': css`
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 11 SemiBold': css`
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 11 Medium': css`
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 11 Regular': css`
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 10 EXTRABOLD': css`
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    text-transform: uppercase;
  `,
  'Hint / 10 ExtraBold': css`
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 10 BOLD': css`
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  `,
  'Hint / 10 Bold': css`
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 10 Medium': css`
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 10 SemiBold': css`
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: none;
  `,
  'Hint / 9 ExtraBold': css`
    font-size: 9px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-transform: none;
  `,
  'Hint / 8 EXTRABOLD': css`
    font-size: 8px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-transform: none;
  `,
  'Hint / 8 ExtraBold': css`
    font-size: 8px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    text-transform: none;
  `,
  'Hint / 8 SemiBold': css`
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    text-transform: none;
  `,
  'Hint / 8 Medium': css`
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    text-transform: none;
  `
}

export type TTypography = typeof typography
