import { reducer, sliceKey } from 'astra-core/containers/StorageProvider/slice'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { localStorageProviderSaga } from './saga'

export const useLocalStorageProvider = () => {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: 'localStorageProvider', saga: localStorageProviderSaga })
}
