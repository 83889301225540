import loadable from '@loadable/component'

import { LoaderSpinnerProps } from '../LoaderSpinner/types'

import {
  StyledLoaderContainer,
  StyledLoaderFullContainer
} from './LoaderLogo.styled'
import * as loaderLottieAnimation from './LoaderLottie.json'

const LottieNoSSR = loadable(() => import('react-lottie'), {
  ssr: false
})

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderLottieAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export const LoaderLogo: React.FC<{
  minimized?: boolean
  size?: LoaderSpinnerProps['size']
}> = ({ minimized, size }) => {
  if (minimized) {
    return <LottieNoSSR height={60} options={defaultOptions} width={60} />
  }

  return (
    <StyledLoaderFullContainer>
      <StyledLoaderContainer>
        {size ? (
          <LottieNoSSR height={size} options={defaultOptions} width={size} />
        ) : (
          <LottieNoSSR height={60} options={defaultOptions} width={60} />
        )}
      </StyledLoaderContainer>
    </StyledLoaderFullContainer>
  )
}
