import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { StyledFooterTopHelpSupport } from 'entities/footer-of-page'
import { Button } from 'shared/ui/Button'
import { ERoutes } from 'shared/types/routes'

export const OpenSupport = () => {
  const SUPPORT_ENABLED = useFeatureFlag('SUPPORT_ENABLED' as EFeatureFlags)
  const [t] = useTranslation()

  if (!SUPPORT_ENABLED) return null

  return (
    <NavLink to={ERoutes.Support}>
      <Button view="secondary-large">
        <StyledFooterTopHelpSupport>
          {t('support service')}
        </StyledFooterTopHelpSupport>
      </Button>
    </NavLink>
  )
}
