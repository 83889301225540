import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { EntityTag, EventStatus } from 'betweb-openapi-axios'

import { EAdditionalSportsCodes } from 'shared/ui/Icon/sport-icon/sport-icon.types'

import { EntityWithTags } from './interface'

export const entityTagsColorsSchema = {
  TOP: '#0D80F2',
  SUPERTOP: '#FF6B00',
  MATCH_OF_THE_DAY: '#E61919',
  FIGHT_OF_THE_DAY: '#E61919',
  EVENT_OF_THE_DAY: '#E61919'
}

export const enum ETagsTop {
  TOP = 'TOP',
  SUPERTOP = 'SUPERTOP',
  EVENT_DAY = 'EVENT DAY'
}

export const ENTITY_TAGS_LIST = [
  ETagsTop.TOP,
  ETagsTop.SUPERTOP,
  ETagsTop.EVENT_DAY
]

export const getEntityTag = (
  entity: EntityWithTags,
  entityTags: Dictionary<EntityTag>
) =>
  entity.tags?.find((tag) =>
    ENTITY_TAGS_LIST.includes(entityTags[tag]?.name.toUpperCase() as ETagsTop)
  )

export const getTopTagIds = (entityTags: Dictionary<EntityTag>) =>
  Object.entries(entityTags)
    .filter(([_key, value]) =>
      ENTITY_TAGS_LIST.includes(value.name.toUpperCase() as ETagsTop)
    )
    .map(([key]) => +key)

export const getEntityTagName = (
  entity: EntityWithTags,
  entityTags: Dictionary<EntityTag>
) => {
  const tagId = getEntityTag(entity, entityTags)

  if (!tagId) {
    return null
  }

  return entityTags[tagId].name.toUpperCase() as ETagsTop
}

export const HIDDEN_FAVORITES_STATUSES = [
  EventStatus.Ended,
  EventStatus.Cancelled,
  EventStatus.Retired,
  EventStatus.Closed
]

export const enum EventDayTagType {
  MATCH_OF_THE_DAY = 'MATCH_OF_THE_DAY',
  FIGHT_OF_THE_DAY = 'FIGHT_OF_THE_DAY',
  EVENT_OF_THE_DAY = 'EVENT_OF_THE_DAY'
}

export const getEventStatusBySport = (
  sportCode: ESportsCodes | EAdditionalSportsCodes
) => {
  switch (sportCode) {
    case ESportsCodes.FOOTBALL:
    case ESportsCodes.ICE_HOCKEY:
    case ESportsCodes.BASEBALL:
      return EventDayTagType.MATCH_OF_THE_DAY
    case ESportsCodes.MMA:
    case ESportsCodes.BOX:
    case ESportsCodes.MIXED_MARTIAL_ARTS:
    case EAdditionalSportsCodes.POPMMA:
      return EventDayTagType.FIGHT_OF_THE_DAY
    case EAdditionalSportsCodes.POLITICS:
    case EAdditionalSportsCodes.CULTURE:
    case EAdditionalSportsCodes.ANIMALS:
      return EventDayTagType.EVENT_OF_THE_DAY
    default:
      return EventDayTagType.EVENT_OF_THE_DAY
  }
}
