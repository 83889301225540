import { FC, useState, MouseEvent, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { selectIsMakeCashoutLoading } from 'astra-core/containers/BetsHistoryProvider'
import { useRouteMatch } from 'react-router-dom'

import { DISABLE_HOVER_ON_CHILD } from 'widgets/bets-history/components/constants'
import { Tooltip } from 'shared/ui/Tooltip'
import {
  cashoutApi,
  LegacyBet,
  SaveCashoutRequest
} from 'entities/legacy-api/lib'
import { MakeCashoutLoading } from 'features/basket-history/ui/BasketHistoryCashout/components/CurrentCashoutModal/constants'
import { setItemLocalStorage } from 'shared/lib/@system'
import { MAKE_CASHOUT_LS } from 'widgets/bets-history-legacy/components'
import { ERoutes } from 'shared/types/routes'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { CurrentCashoutModal } from '../CurrentCashoutModal'
import { StyledMakeCashoutLoadingWrapper } from '../CurrentCashoutModal/CurrentCashoutModal.styled'

import {
  StyledCellEventCashoutText,
  StyledCellEventCashoutWrapper,
  CurrentCashoutModalWholeBackground
} from './CellEventCashout.styled'
import { useCashoutToast } from './use-cashout-toast'

const CellEventCashoutText = memo(() => {
  const [t] = useTranslation()
  const isMakeCashoutLoading = useAppSelector(selectIsMakeCashoutLoading)

  const text = `${t('sell bet')}`

  return (
    <Tooltip
      isCommon={!isMakeCashoutLoading}
      title={t('currently impossible to sell a bet')}
    >
      <StyledCellEventCashoutText
        className={DISABLE_HOVER_ON_CHILD}
        disabled={isMakeCashoutLoading}
        isLoading={isMakeCashoutLoading}
        view="secondary-large"
      >
        {text}
      </StyledCellEventCashoutText>
    </Tooltip>
  )
})

interface Props {
  bet: LegacyBet
  className?: string
}

const { useGetCashoutQuery, useSaveCashoutMutation } = cashoutApi

export const CellEventCashout: FC<Props> = ({ bet, className }) => {
  const [visible, setVisible] = useState(false)

  const match = useRouteMatch([ERoutes.BetsHistory])

  const { showErrorToast, showSuccessToast } = useCashoutToast()

  const [saveCashout, { isLoading: isSaveLoading, data: errorCashout }] =
    useSaveCashoutMutation()

  const { data, isLoading: isGetLoading } = useGetCashoutQuery(
    { bet_id: bet.bet_id },
    { skip: !bet.cashout_allowed }
  )

  const unsetCurrentCashout = () => {
    setVisible(false)
  }

  const setCurrentCashout = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setVisible(true)

    setItemLocalStorage(MAKE_CASHOUT_LS, {
      betId: bet.bet_id,
      amount: data?.data.amount
    })
  }

  const handleBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    unsetCurrentCashout()
  }

  const isLoading = isGetLoading || isSaveLoading

  const handleCashoutSave = async (args: SaveCashoutRequest) => {
    const response = await saveCashout(args)

    if (response?.data?.data?.status === 'error') {
      showErrorToast()
    } else {
      showSuccessToast()
    }

    return response
  }

  if (!bet.cashout_allowed) {
    return null
  }

  return (
    <StyledCellEventCashoutWrapper
      className={className}
      onClick={setCurrentCashout}
    >
      {isLoading ? (
        <StyledMakeCashoutLoadingWrapper isBasketHistory={!match}>
          <MakeCashoutLoading />
        </StyledMakeCashoutLoadingWrapper>
      ) : (
        <CellEventCashoutText />
      )}

      {visible && !!data && !isLoading && (
        <>
          <CurrentCashoutModal
            bet={bet}
            cashoutResponse={data}
            errorCashout={errorCashout}
            saveCashout={handleCashoutSave}
            onCancel={unsetCurrentCashout}
          />
          <CurrentCashoutModalWholeBackground onClick={handleBackgroundClick} />
        </>
      )}
    </StyledCellEventCashoutWrapper>
  )
}
