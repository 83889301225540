import { FC, useCallback, useState } from 'react'

import { OutcomesGrid } from 'widgets/outcomes-grid/outcomes-grid'
import { OutcomesComponentType } from 'widgets/outcomes-grid/outcomes-grid.types'
import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

import { IEventProps, TScrollValues } from './Event.types'
import { EventBanner } from './components'
import { EventContext } from './Event.context'
import { StyledHeadEventWrapper } from './Event.styled'

export const Event: FC<IEventProps> = ({ event }) => {
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const [scrollHeight, setScrollHeight] = useState(0)
  const hydrated = useHydrated()

  const updatedScrollValues = useCallback(
    ({ scrollTop, clientHeight, scrollHeight }: TScrollValues) => {
      setScrollTop(scrollTop)
      setClientHeight(clientHeight)
      setScrollHeight(scrollHeight)
    },
    []
  )

  return (
    <EventContext.Provider value={{ event }}>
      <StyledHeadEventWrapper>
        <EventBanner
          clientHeight={clientHeight}
          scrollHeight={scrollHeight}
          scrollTop={scrollTop}
        />
        {hydrated && (
          <OutcomesGrid
            eventId={event.id}
            type={OutcomesComponentType.EVENT_PAGE}
            onScrollCallback={updatedScrollValues}
          />
        )}
      </StyledHeadEventWrapper>
    </EventContext.Provider>
  )
}
