import React, {
  Children,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import useMeasure from 'react-use-measure'
import { mergeRefs } from 'react-merge-refs'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useTranslation } from 'react-i18next'

import { checkOverflow } from 'shared/lib/text-overflow/check-overflow'
import { IconKebab } from 'shared/ui/Icon/General/icon-kebab'
import { useClickOutside } from 'shared/lib/click-outside/click-outside'
import { useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './tabs.styled'
import { DropdownWrapper, OverflowButton } from './tabs.styled'

const TABS_GAP = 4

export const Tabs: FC<PropsWithChildren> = ({ children }) => {
  const hiddenContainerRef = useRef<HTMLDivElement>(null)
  const [overflowState, setOverflowState] = useState(false)
  const [tabsWidth, setTabsWidth] = useState<number[]>([])
  const [measureRef, { width: containerWidth }] = useMeasure()
  const { IS_TRANSIT } = useAppSelector(selectConfig)
  const [t] = useTranslation()

  // Calculate tabs width based on hidden container
  useEffect(() => {
    if (hiddenContainerRef.current) {
      const hiddenChildren = hiddenContainerRef.current.children
      const overflow = checkOverflow(hiddenContainerRef.current)
      if (overflowState !== overflow) {
        requestAnimationFrame(() => {
          setOverflowState(overflow)
        })
      }
      const tabWidth: number[] = []
      for (let i = 0; i < hiddenChildren.length; i += 1) {
        const child = hiddenChildren.item(i)
        if (child) {
          tabWidth.push(child.getBoundingClientRect().width)
        }
      }
      requestAnimationFrame(() => {
        setTabsWidth(tabWidth)
      })
    }
  }, [overflowState, containerWidth])

  const [lastVisibleIndex, setLastVisibleIndex] = useState(
    Children.count(children)
  )

  // Set pointer to last visible element
  useEffect(() => {
    let totalWidth = 0
    let newLastVisibleIndex = 0

    for (let index = 0; index < tabsWidth.length; index += 1) {
      if (
        totalWidth + tabsWidth[index] + TABS_GAP * (index + 1) >
        containerWidth - (IS_TRANSIT ? 130 : 36 + TABS_GAP) * +overflowState
      ) {
        break
      }
      totalWidth += tabsWidth[index]
      newLastVisibleIndex = index
    }

    requestAnimationFrame(() => {
      setLastVisibleIndex(newLastVisibleIndex)
    })
  }, [
    IS_TRANSIT,
    children,
    containerWidth,
    lastVisibleIndex,
    overflowState,
    tabsWidth
  ])

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setIsDropdownVisible((prevState) => !prevState)
  }

  const closeDropdown = useCallback(() => {
    if (isDropdownVisible) {
      setIsDropdownVisible((prevState) => !prevState)
    }
  }, [isDropdownVisible])

  const dropdownRef = useClickOutside<HTMLDivElement>(closeDropdown)

  return (
    <S.AnchorWrapper style={{ overflow: overflowState ? 'visible' : 'hidden' }}>
      <S.HiddenContainer ref={mergeRefs([measureRef, hiddenContainerRef])}>
        {children}
        {IS_TRANSIT && (
          <S.StyledOldWebsiteLinkWrapper>
            <S.Divider />
            <S.StyledOldWebsiteLink href="https://olimpbet.kz">
              {t('old website')}
            </S.StyledOldWebsiteLink>
          </S.StyledOldWebsiteLinkWrapper>
        )}
      </S.HiddenContainer>
      <S.VisibleContainer>
        {Children.map(children, (child, index) => {
          if (index > lastVisibleIndex) {
            return null
          }
          return <>{child}</>
        })}
        {overflowState && (
          <OverflowButton onClick={toggleDropdown}>
            <IconKebab size={24} />
            {isDropdownVisible && (
              <DropdownWrapper ref={dropdownRef}>
                {Children.map(children, (child, index) => {
                  if (index <= lastVisibleIndex) {
                    return null
                  }
                  return <>{child}</>
                })}
              </DropdownWrapper>
            )}
          </OverflowButton>
        )}
        {IS_TRANSIT && (
          <S.StyledOldWebsiteLinkWrapper>
            <S.Divider />
            <S.StyledOldWebsiteLink href="https://olimpbet.kz">
              {t('old website')}
            </S.StyledOldWebsiteLink>
          </S.StyledOldWebsiteLinkWrapper>
        )}
      </S.VisibleContainer>
    </S.AnchorWrapper>
  )
}

export const Tab = S.StyledTabItem
