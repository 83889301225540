import { ReactNode } from 'react'

import { IRoute } from 'shared/types/routes'

import { IFilterRoutes, IRouteTranslate } from './filterRoutes.types'

export const filterRoutes = <Route extends IRouteTranslate>({
  routeList,
  t,
  isIframe,
  forwardProps
}: IFilterRoutes<Route>) =>
  routeList
    .filter((item) => (isIframe ? !item.hideInIframe : true))
    .map((item) => ({
      path: item.path,
      translatedName: t(item.locale),
      icon: item.icon,
      ...forwardProps?.(item)
    })) as {
    path: string
    translatedName: string
    icon: ReactNode
  }[]

export const isActiveCurrentPath = (
  path: string,
  itemPath: IRoute['path'] | string
) => typeof itemPath === 'string' && `${path}/`.includes(`${itemPath}/`)
