import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { formatAmountLocaleString, useGetCurrencyIcon } from 'astra-core'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { useRouteMatch } from 'react-router-dom'

import {
  GetCashoutResponse,
  SaveCashoutRequest,
  SaveCashoutResponse
} from 'entities/legacy-api/lib/cashout/cashout.types'
import {
  LegacyBet,
  LegacyResponseError,
  markBetSold
} from 'entities/legacy-api'
import { ERoutes } from 'shared/types/routes'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { DISABLE_HOVER_ON_CHILD } from '../../../constants'

import { StyledCurrentCashoutWrapper } from './CurrentCashoutModal.styled'
import { getCurrentCashoutModalContent } from './constants'

export const CurrentCashoutModal: FC<{
  cashoutResponse: GetCashoutResponse
  errorCashout?: SaveCashoutResponse
  bet: LegacyBet
  onCancel: () => void
  saveCashout: (
    args: SaveCashoutRequest
  ) => Promise<
    | { data: SaveCashoutResponse }
    | { error: FetchBaseQueryError | SerializedError }
  >
}> = ({ cashoutResponse, bet, onCancel, saveCashout, errorCashout }) => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const match = useRouteMatch([ERoutes.BetsHistory])

  const cashout = cashoutResponse.data
  const currencyIcon = useGetCurrencyIcon()
  const { amount } = cashout

  const cashoutAmount = `${t('sell for')} ${formatAmountLocaleString({
    value: amount,
    currency: currencyIcon
  })}`

  const sellingBetIdText = `${t('selling a bet')} #${bet.bet_id}`
  const errCode = errorCashout?.data?.status === 'error' ? 1 : 0

  const cashoutError: LegacyResponseError = {
    err_code: errCode,
    err_desc: `${sellingBetIdText} ${t('bet unavailable')}`
  }

  const sellBetText = `${sellingBetIdText}. ${cashoutAmount}`

  const makeCashout = async () => {
    await saveCashout({
      bet_id: bet.bet_id,
      amount
    })

    dispatch(markBetSold({ betId: bet.bet_id }))
  }

  const closeCashout = () => {
    onCancel()
  }

  return (
    <StyledCurrentCashoutWrapper
      className={DISABLE_HOVER_ON_CHILD}
      isBasketHistory={!match}
      isMakeCashoutLoading={false}
    >
      {getCurrentCashoutModalContent({
        cashoutError,
        cashout,
        cashoutAmount: sellBetText,
        makeCashout,
        closeCashout
      })}
    </StyledCurrentCashoutWrapper>
  )
}
