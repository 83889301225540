import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { selectEventsLayoutIsLoading } from 'entities/line-tournaments/model/selectors'
import { selectLiveDayEventsBySearch } from 'entities/live/model/selectors'
import { LiveEventsParams } from 'entities/live/model/types'
import { useAppSelector } from 'shared/lib/@reduxjs'
import { RootState } from 'shared/types/store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { EventsTable } from 'widgets/events-table'

export const TopLiveEvents: FC = () => {
  const { sportId } = useParams<LiveEventsParams>()
  const eventDayEvents = useAppSelector((state: RootState) =>
    selectLiveDayEventsBySearch(state, { sportId })
  )

  const isLayoutEventsLoading = useAppSelector(selectEventsLayoutIsLoading)

  return (
    <>
      {isLayoutEventsLoading ? (
        <LoaderSpinner />
      ) : eventDayEvents.length ? (
        <EventsTable events={eventDayEvents} isDayEvent />
      ) : (
        <></>
      )}
    </>
  )
}
