import {
  EMakeBetStatus,
  basketProviderActions,
  selectBasketHeaderTab,
  selectBasketMakeBetStatus,
  selectBasketOutcomesLength
} from 'astra-core/containers/BasketProvider'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { selectIsDisplayModalCouponSetting } from 'containers/LayoutContainer/selectors'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { selectIsShowBasketOnSmallWindow } from 'entities/basket/model/selectors'
import { rightBasketContainerActions } from 'entities/basket/model/slice'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'
import { IconBasketClose } from 'shared/ui/Icon/General/IconBasketClose'
import { IconCouponSetting } from 'shared/ui/Icon/General/IconCouponSetting'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { IconState } from 'shared/ui/buttons/icon-state'

import { EBasketHeaderTypes } from '../../model/basket-header-type'

import {
  BasketTitleItemCounter,
  BasketTitleItemNum,
  BasketTitleItemTitle,
  BasketTitleTabs,
  StyledBasketTitleItemWrapper,
  StyledBasketTitleWrapper,
  StyledBasketToolbar,
  StyledCloseButtonWrapper
} from './header.styled'
import { BasketTitleItemProps, BasketTitleItemsLocale } from './header.types'

const basketTitleItemsLocale: BasketTitleItemsLocale[] = [
  { name: EBasketHeaderTypes.Coupon, locale: 'just coupon' },
  { name: EBasketHeaderTypes.History, locale: 'history' }
]

export const Header: FC = () => {
  const dispatch = useAppDispatch()
  const isShowBasketOnSmallWindow = useAppSelector(
    selectIsShowBasketOnSmallWindow
  )
  const isShowModalCouponSetting = useAppSelector(
    selectIsDisplayModalCouponSetting
  )
  const currentBasketBetStatus = useAppSelector(selectBasketMakeBetStatus)

  const toggleShowBasket = useCallback(() => {
    dispatch(
      rightBasketContainerActions.toggleBasketOnSmallWindow({ isShow: false })
    )
  }, [dispatch])

  const handleCouponSettingToggle = useCallback(() => {
    dispatch(
      layoutContainerActions.setIsDisplayModalCouponSetting(
        !isShowModalCouponSetting
      )
    )
  }, [dispatch, isShowModalCouponSetting])

  return currentBasketBetStatus === EMakeBetStatus.init ? (
    <StyledBasketTitleWrapper>
      <BasketTitleTabs>
        {basketTitleItemsLocale.map(({ name, locale }) => (
          <BasketTitleItem key={name} locale={locale} name={name} />
        ))}
      </BasketTitleTabs>

      <StyledBasketToolbar>
        <IconState
          icon={IconCouponSetting}
          isActive={isShowModalCouponSetting}
          size="m"
          onClick={handleCouponSettingToggle}
        />

        {isShowBasketOnSmallWindow && (
          <StyledCloseButtonWrapper onClick={toggleShowBasket}>
            <IconBasketClose size={10} />
          </StyledCloseButtonWrapper>
        )}
      </StyledBasketToolbar>
    </StyledBasketTitleWrapper>
  ) : null
}

export const BasketTitleItem: FC<BasketTitleItemProps> = ({ name, locale }) => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()

  const headerTab = useAppSelector(selectBasketHeaderTab)
  const outcomesLength = useAppSelector(selectBasketOutcomesLength)

  const handleTabChange = useCallback(() => {
    dispatch(basketProviderActions.handleTabChange({ headerTab: name }))
  }, [dispatch, name])

  return (
    <StyledBasketTitleItemWrapper
      active={name === headerTab}
      onClick={handleTabChange}
    >
      <BasketTitleItemTitle active={name === headerTab}>
        {t(locale)}
      </BasketTitleItemTitle>
      {name === EBasketHeaderTypes.Coupon && outcomesLength > 0 && (
        <BasketTitleItemCounter>
          <IconPolygonBgLeftPart />
          <BasketTitleItemNum>{outcomesLength}</BasketTitleItemNum>
          <IconPolygonBgLeftPart twist={180} />
        </BasketTitleItemCounter>
      )}
    </StyledBasketTitleItemWrapper>
  )
}
