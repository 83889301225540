import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledPersonalDataContainer = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledWarningAboutHarm = styled(NavLink)`
  ${typography['Body / 12 Medium']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-description']};

    &:hover {
      color: ${theme.tokens.colors.text['text-secondary-3']};
    }
  `}
`
