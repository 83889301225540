import { Route, Switch } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { getCurrentLng, loadLocaleDayjs } from 'astra-core'
import { AuthMessageProvider } from 'astra-core/containers/AuthMessageProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import { useLoadLocaleDayjs } from 'astra-core/hooks/useLocaleDayjs'

import { MetaProvider } from 'app/providers/meta-provider'
import { OnboardingPortal } from 'widgets/onboarding' // Injecting static styles, such as fonts, etc
import { HydratedProvider } from 'shared/lib/hydrated/hydrated-provider'
import { PageAuthSuccessLazy } from 'pages/page-auth-success'
import { PageAuthSuccessSignUpLazy } from 'pages/page-auth-success-sign-up'
import { ERoutes } from 'shared/types/routes'
import {
  useToastifyCashoutModal,
  useResetLineFilter,
  useSetStorage,
  useToastifyBroadcastModal,
  useToastifyMyBonusesModal
} from 'hooks'
import { IdentificationSetConstraintsDecorator } from 'entities/constration'
import { useAppSelector } from 'shared/lib/@reduxjs'

import {
  ThemeProvider,
  GlobalStyle,
  ErrorBoundary,
  AppRoutes
} from './providers'
import { useProviders } from './lib/providers'
import { AppLayout } from './providers/app-layout'

import './styles/index.css'

loadLocaleDayjs()

const shouldForwardProp = (propName: string, elementToBeCreated: any) => {
  if (['height', 'width'].includes(propName)) {
    return false
  }

  const isHtmlElelement =
    typeof elementToBeCreated === 'string' ||
    (typeof elementToBeCreated === 'function' &&
      elementToBeCreated.name === 'AppLink')

  return isHtmlElelement ? isPropValid(propName) : true
}

const App = ({ routes }) => {
  useProviders()
  useSetStorage()
  useLoadLocaleDayjs()
  useToastifyCashoutModal()
  useResetLineFilter()
  useToastifyBroadcastModal()
  useToastifyMyBonusesModal()

  const config = useAppSelector(selectConfig)

  return (
    <HydratedProvider>
      <StyleSheetManager
        shouldForwardProp={shouldForwardProp}
        enableVendorPrefixes
      >
        <ThemeProvider>
          <MetaProvider />
          <GoogleReCaptchaProvider
            language={getCurrentLng()}
            reCaptchaKey={config.CAPTCHA_KEY}
          >
            <AuthMessageProvider>
              <GlobalStyle />
              <ErrorBoundary>
                <IdentificationSetConstraintsDecorator>
                  <Switch>
                    <Route
                      component={PageAuthSuccessLazy}
                      path={ERoutes.SuccessLoginPage}
                    />
                    <Route
                      component={PageAuthSuccessSignUpLazy}
                      path={ERoutes.SuccessFastSignUp}
                    />
                    <Route
                      component={PageAuthSuccessSignUpLazy}
                      path={ERoutes.SuccessFullSignUp}
                    />
                    <Route path="*">
                      <AppLayout>
                        <Switch>
                          <AppRoutes routes={routes} />
                        </Switch>
                      </AppLayout>
                    </Route>
                  </Switch>
                </IdentificationSetConstraintsDecorator>
              </ErrorBoundary>
              <OnboardingPortal />
            </AuthMessageProvider>
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </HydratedProvider>
  )
}

// eslint-disable-next-line import/no-default-export
export default App
