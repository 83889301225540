import styled, { css } from 'styled-components'

import { MAIN_HEADER_HEIGHT } from 'shared/lib/layout'
import { AppLink } from 'shared/lib/app-link'
import { typography } from 'shared/lib/theme/typography'

export const HeaderWrapper = styled.tr<{ hideHeader: boolean }>`
  ${typography['Body / 12 Medium']};
  ${({ theme, hideHeader }) => css`
    align-items: center;
    background-color: ${theme.tokens.colors.surface['surface-event-title']};
    box-shadow: inset 0 -1px 0 ${theme.tokens.colors.divider['divider-primary']};
    color: ${theme.tokens.colors.text['text-secondary-2']};
    height: 28px;
    position: sticky;
    top: ${hideHeader ? 0 : MAIN_HEADER_HEIGHT}px;
    z-index: 1;
  `}
`

export const StyledTitleCell = styled.th`
  padding-left: 16px;
  text-align: start;
  width: 100%;
`

export const SportIconWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
  display: flex;
  span {
    height: 14px;
    width: 14px;
  }
`
export const MainInfoWrapper = styled.div`
  align-items: center;
  display: grid;
  flex: 1;
  grid-template-columns: auto 1fr;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
  padding-right: 8px;
`

export const MainInfoIconsBlock = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const TournamentNameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledBlocksCell = styled.th`
  padding-left: 4px;
`

export const StyledBlocksWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`

export const BlockWrapper = styled.div<{ isMerged?: boolean }>`
  display: flex;
  ${(p) =>
    p.isMerged
      ? css`
          gap: 1px;
        `
      : css`
          gap: 2px;
        `}
`

export const HeaderCell = styled.div<{ customWidth?: number }>`
  ${typography['Hint / 11 Medium']};
  text-align: center;
  width: ${(p) => (p.customWidth ? `${p.customWidth}px` : '36px')};
`

export const BackButton = styled(AppLink)`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin: 0;
  outline: none;
  padding: 0;
`
