import { useMemo } from 'react'

import { useAppSelector } from 'shared/lib/@reduxjs'

import { ETagsTop, getEntityTagName } from './constants'
import { EntityWithTags } from './interface'
import { selectEntityTags } from './selectors'

export const sortByEntityTags = (array: EntityWithTags[], entityTags) => {
  const { superTop, top, other } = array.reduce(
    (acc, entity) => {
      const tagName = getEntityTagName(entity, entityTags)

      if (tagName === ETagsTop.SUPERTOP) {
        acc.superTop.push(entity)
      } else if (tagName === ETagsTop.TOP) {
        acc.top.push(entity)
      } else {
        acc.other.push(entity)
      }

      return acc
    },
    {
      superTop: [] as EntityWithTags[],
      top: [] as EntityWithTags[],
      other: [] as EntityWithTags[]
    }
  )

  return [...superTop, ...top, ...other]
}

export const useSortByIncludingTags = (
  array: { events: EntityWithTags[] }[]
) => {
  const entityTags = useAppSelector(selectEntityTags)

  return useMemo(() => {
    const { superTop, top, other } = array.reduce(
      (acc, entity) => {
        const sortedEvents = sortByEntityTags(entity.events, entityTags)

        const tagName = getEntityTagName(sortedEvents[0], entityTags)

        if (tagName === ETagsTop.SUPERTOP) {
          acc.superTop.push(entity)
        } else if (tagName === ETagsTop.TOP) {
          acc.top.push(entity)
        } else {
          acc.other.push(entity)
        }

        return acc
      },
      {
        superTop: [] as EntityWithTags[],
        top: [] as EntityWithTags[],
        other: [] as EntityWithTags[]
      }
    )

    return [...superTop, ...top, ...other]
  }, [array, entityTags])
}
