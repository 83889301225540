import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'
import { useParams } from 'react-router-dom'

import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { getFetchLineTournamentsReqData } from 'entities/line-tournaments/model/utils'
import { LiveContainer } from 'entities/live'
import { liveContainerActions } from 'entities/live/model/slice'
import { LiveEventsParams } from 'entities/live/model/types'
import { getFetchLiveReqData } from 'entities/live/model/utils'
import { useRedirectToSport } from 'hooks/useRedirectToSport'
import { LayoutEventsTable } from 'layouts/events-table'
import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import {
  EventsPageHeader,
  EventsPageHeaderTypes
} from 'widgets/events-page-header'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'

import { StyledLiveTableWrapper } from './page.styled'
import { LiveTable, TopLiveEvents } from './ui'

export const PageLiveEvents = () => {
  const { sportId } = useParams<LiveEventsParams>()

  useRedirectToSport({
    sportId: +sportId,
    currentTabMenu: ESportsMenuTabTypes.LIVE
  })

  return (
    <LayoutEventsTable>
      <LiveContainer>
        <StyledLiveTableWrapper>
          <EventsPageHeader type={EventsPageHeaderTypes.Live} />
          <TopLiveEvents />
          <LiveTable />
        </StyledLiveTableWrapper>
      </LiveContainer>
    </LayoutEventsTable>
  )
}

export const pageLiveEventsPrefetch = ({ store, req }) => {
  const sportId = getRouteParamAtIndex(req.params, 1) as string

  if (sportId) {
    store.dispatch(
      liveContainerActions.fetchLive(
        getFetchLiveReqData({
          sportId
        })
      )
    )

    // TODO remove after tags will be added to GET /events
    store.dispatch(
      lineTournamentsContainerActions.fetchLineTournaments(
        getFetchLineTournamentsReqData({ sportId })
      )
    )
  }
  store.dispatch(sportCountersProviderActions.fetchLiveSportCounters({}))
}

export default PageLiveEvents
