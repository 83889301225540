/* eslint-disable react/jsx-sort-props */
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import DatePickerLib from 'react-datepicker'
import { useGetLocaleDayjs } from 'astra-core/hooks/useLocaleDayjs'
import { ETestData } from 'astra-core/utils'
import { dayjsCore as dayjs } from 'astra-core'

import { IconChevronDown } from '../Icon/General/IconChevronDown'

import { CustomDatePickerProps, IDatePickerRange } from './types'
import { CustomButtonRange, CustomContainerRange } from './components'
import { StyledDatePicker } from './styled'

import 'react-datepicker/dist/react-datepicker.css'

/**
 onChange -> args was back ([startDate, endDate]) -> handler for click to cell with the date

 onChangeInputRange -> args was back ({startDate, endDate}) -> handler for input of dates

 onChangeSelect -> args was back ([startDate, endDate], value), where value is number of selected item -> handler for select

 selectValue -> value for show item of select
 */

export const DatePickerRange: FC<IDatePickerRange> = memo(
  ({
    onChange,
    onChangeInputRange,
    onCalendarClose = () => {},
    rangeCalendarDates,
    onChangeSelect,
    selectValue,
    onCalendarOpen = () => {},
    maxDate,
    ignoreContainer
  }) => {
    const [open, setOpen] = useState(false)

    const handleOpenPicker = useCallback(() => {
      setOpen(true)
    }, [])

    const handleClosePicker = useCallback(() => {
      setOpen(false)
    }, [])

    const CalendarContainer = useMemo(
      () => (props) =>
        (
          <CustomContainerRange
            rangeCalendarDates={rangeCalendarDates}
            selectValue={selectValue}
            onChangeInputRange={onChangeInputRange}
            onChangeSelect={onChangeSelect}
            handleClosePicker={handleClosePicker}
            {...props}
          />
        ),
      [onChangeInputRange, onChangeSelect, rangeCalendarDates, selectValue] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const popperProps = useMemo(
      () => (ignoreContainer ? { strategy: 'fixed' } : undefined),
      [ignoreContainer]
    )

    const handleClose = useCallback(() => {
      onCalendarClose()
    }, [onCalendarClose])

    const handleOpen = useCallback(() => {
      onCalendarOpen()
    }, [onCalendarOpen])

    useEffect(() => {
      if (rangeCalendarDates?.endDate && rangeCalendarDates?.startDate) {
        handleClosePicker()
      }
    }, [rangeCalendarDates?.endDate, rangeCalendarDates?.startDate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <DatePicker
        {...rangeCalendarDates}
        customInput={
          <CustomButtonRange
            {...rangeCalendarDates}
            active={open}
            onReset={onChange}
            handleOpenPicker={handleOpenPicker}
          />
        }
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth
        }) => (
          <div>
            <button
              aria-label="Previous Month"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              style={customHeaderCount === 1 ? { visibility: 'hidden' } : {}}
              onClick={decreaseMonth}
            >
              <IconChevronDown twist={90} />
            </button>
            <span className="react-datepicker__current-month">
              {dayjs(monthDate).format('MMMM YYYY')}
            </span>
            <button
              aria-label="Next Month"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              style={customHeaderCount === 0 ? { visibility: 'hidden' } : {}}
              onClick={increaseMonth}
            >
              <IconChevronDown twist={-90} />
            </button>
          </div>
        )}
        calendarContainer={CalendarContainer}
        calendarStartDay={1}
        monthsShown={2}
        selected={rangeCalendarDates?.startDate}
        onCalendarClose={handleClose}
        onCalendarOpen={handleOpen}
        onChange={onChange}
        isClearable
        showPreviousMonths
        // @ts-ignore
        selectsRange
        {...(maxDate && { showDisabledMonthNavigation: true, maxDate })}
        open={open}
        popperProps={popperProps}
        onClickOutside={handleClosePicker}
      />
    )
  }
)

export const DatePicker: FC<CustomDatePickerProps> = memo((props) => {
  const { weekdaysMin, months } = useGetLocaleDayjs()

  const lang: CustomDatePickerProps['locale'] = {
    localize: {
      day: (n) => weekdaysMin()[n],
      month: (n) => months()[n],
      ordinalNumber: () => {},
      era: () => {},
      quarter: () => {},
      dayPeriod: () => {}
    },
    formatLong: {
      date: () => 'mm/dd/yyyy',
      time: () => {},
      dateTime: () => {}
    }
  }

  return (
    <StyledDatePicker
      data-test-id={ETestData.TestBetsHistoryDatePicker}
      isTodayActive={!!props.isTodayActive}
    >
      <DatePickerLib {...props} locale={lang} />
    </StyledDatePicker>
  )
})
