import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getIsHiddenWelcomeScreen = (state: RootState) =>
  state.layoutContainer.isHiddenWelcomeScreen ??
  initialState.isHiddenWelcomeScreen

export const getIsShowMyBonusesToastifyNotification = (state: RootState) =>
  state.layoutContainer.isShowMyBonusesToastifyNotification ??
  initialState.isShowMyBonusesToastifyNotification

export const getIsDisplayNotificationPanel = (state: RootState) =>
  state.layoutContainer.isDisplayNotificationPanel ??
  initialState.isDisplayNotificationPanel

export const getIsDisplayModalSettings = (state: RootState) =>
  state.layoutContainer.isDisplayModalSettings ??
  initialState.isDisplayModalSettings

export const getIsDisplayModalCouponSetting = (state: RootState) =>
  state.layoutContainer.isDisplayModalCouponSetting ??
  initialState.isDisplayModalCouponSetting

export const getIsDisplayModalChangeLanguage = (state: RootState) =>
  state.layoutContainer.isDisplayModalChangeLanguage ??
  initialState.isDisplayModalChangeLanguage

export const getOnboardingIsDisplay = (state: RootState) =>
  state.layoutContainer.onboarding.isDisplay ??
  initialState.onboarding.isDisplay

export const getOnboardingType = (state: RootState) =>
  state.layoutContainer.onboarding.type || initialState.onboarding.type

export const selectIsHiddenWelcomeScreen = getIsHiddenWelcomeScreen

export const selectIsShowMyBonusesToastifyNotification =
  getIsShowMyBonusesToastifyNotification

export const selectIsDisplayModalSettings = getIsDisplayModalSettings

export const selectIsDisplayModalCouponSetting = getIsDisplayModalCouponSetting

export const selectIsDisplayModalChangeLanguage =
  getIsDisplayModalChangeLanguage
