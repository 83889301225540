import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTranslation } from 'react-i18next'
import { bonusOnExpressToPercent, formatAmountLocaleString } from 'astra-core'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { BetStatus, BetType } from 'betweb-openapi-axios'

import {
  BetsHistoryHeader,
  BetsHistoryOutcomesTable
} from 'features/bets-history-outcomes-table-legacy'
import { CellEventCashout } from 'widgets/bets-history-legacy/components/Cashout'
import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { IconInfoCircleSFilled } from 'shared/ui/Icon/General/IconInfoCircleSFilled'
import {
  CardDataAmountValue,
  CardDataCoupon,
  CouponAmount,
  CouponTitle
} from 'features/bet-history-card/bet-history-card.styled'
import { computed } from 'shared/lib/computed'
import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'

import {
  getBetStatusFromLegacy,
  getBetTypeFromLegacy
} from '../../entities/legacy-api/lib/utils'
import { getLegacyBetBonusAmountFormatted } from '../../entities/bet-history'
import { useBetsHistoryContext } from '../../entities/bet-history/lib/bet-history.context'

import { BetHistoryCardLegacyIcon } from './bet-history-card-legacy-icon'
import {
  getExpressSystemOutcomesCount,
  isBonusBalanceBet,
  isCouponBonusBet
} from './bet-history-utils-legacy'
import { BetHistoryTagLegacy } from './bet-history-tag-legacy'
import {
  BetHistoryCardProps,
  ECellEventTitle
} from './bet-history-card-legacy.types'
import {
  CardDataBlockAmount,
  CardDataBlockBonus,
  CardDataBlockCashout,
  CardDataBlockName,
  CardDataBlockOdd,
  CardDataBlockTag,
  CardDataFixedContainer,
  CardDataLabel,
  CardDataValue,
  CardDate,
  CardMain,
  CardName,
  CardTable,
  CardWrapper,
  CollapseCardIcon,
  CollapsedWrapper,
  ExpressBonusContent,
  ExpressBonusLabel,
  ExpressBonusPercent,
  ExpressBonusPercentContent,
  MainInfoBlock,
  StyledBonusIconWrapper,
  StyledEmptySpace,
  WonAmount
} from './bet-history-card-legacy.styled'

export const BetHistoryCardLegacy: FC<BetHistoryCardProps> = ({ item }) => {
  const { t } = useTranslation()
  const currencyIcon = useGetCurrencyIcon()

  const bonusAsPercent = bonusOnExpressToPercent(item.bonus_exp ?? 1)

  const betAmountFormatted = item.pay_sum
    ? formatAmountLocaleString({
        value: Number(item.pay_sum).toFixed(2),
        currency: currencyIcon
      })
    : getLegacyBetBonusAmountFormatted({
        bet: item,
        currencyIcon
      })

  const outcomes = item.events.filter((e) => !!e.sport_id)

  const betCount = computed(() => {
    const outcomesCount = getExpressSystemOutcomesCount(item, outcomes)

    if (outcomes.length < 2) return ''
    if (getBetTypeFromLegacy(item) === BetType.System) {
      return outcomesCount.toLowerCase()
    }
    return `${outcomesCount} ${t('events', {
      count: outcomes.length
    }).toLowerCase()}`
  })

  const status = getBetStatusFromLegacy(item)
  const betType = getBetTypeFromLegacy(item)
  const betName = ECellEventTitle[betType]
    ? `${t(ECellEventTitle[betType])} ${betCount}`
    : ECellEventTitle.default
  const betTitle = `#${item.bet_id} ${betName}`

  const hideBetAmount =
    !betAmountFormatted ||
    status === BetStatus.Lost ||
    status === BetStatus.Current ||
    status === BetStatus.Returned

  const { allCollapsed } = useBetsHistoryContext()

  const [isCollapsed, setIsExpressSystemCollapsed] = useState(allCollapsed)

  const onClickRowCollapse = useCallback(() => {
    if (betType !== BetType.Ordinar) {
      setIsExpressSystemCollapsed((prev) => !prev)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (betType !== BetType.Ordinar) {
      setIsExpressSystemCollapsed(allCollapsed)
    }
  }, [allCollapsed]) // eslint-disable-line react-hooks/exhaustive-deps

  const isBonusBalance = useMemo(() => isBonusBalanceBet(item), [item])
  const couponAmount = formatAmountLocaleString({
    value: item.total_bet,
    currency: currencyIcon
  })

  return (
    <CollapsedWrapper>
      <CardWrapper>
        <CardMain onClick={onClickRowCollapse}>
          <MainInfoBlock>
            <BetHistoryCardLegacyIcon betStatus={status} />
            <CardDataBlockName>
              <CardName>{betTitle}</CardName>
              <CardDate>
                {dayjs.unix(item.dttm).format('DD.MM.YY HH:mm')}
              </CardDate>
            </CardDataBlockName>
          </MainInfoBlock>

          <CardDataBlockCashout>
            <CellEventCashout bet={item} />
          </CardDataBlockCashout>

          <CardDataBlockAmount justifyContent="flex-end">
            <CardDataLabel>{t('bet amount')}</CardDataLabel>

            {isCouponBonusBet(item) ? (
              <CardDataCoupon>
                <CouponTitle>
                  <IconTicketNo />
                  {t('coupon applied')}
                </CouponTitle>
                <CouponAmount>{couponAmount}</CouponAmount>
              </CardDataCoupon>
            ) : (
              <CardDataAmountValue>
                {formatAmountLocaleString({
                  value: item.total_bet,
                  currency: isBonusBalance ? '' : currencyIcon
                })}
                {isBonusBalance ? <IconBonus size={12} /> : null}
              </CardDataAmountValue>
            )}
          </CardDataBlockAmount>

          <CardDataBlockOdd justifyContent="flex-end">
            {![BetType.Ordinar, BetType.System].includes(betType) ? (
              <>
                <CardDataLabel>{t('total odds')}</CardDataLabel>
                <CardDataValue>
                  {formatAmountLocaleString({
                    value: item.final_odd?.toFixed(2)
                  })}
                </CardDataValue>
              </>
            ) : null}
          </CardDataBlockOdd>

          <CardDataFixedContainer>
            {betType !== BetType.System || status !== BetStatus.Current ? (
              <>
                {betType === BetType.Express &&
                status !== BetStatus.Sold &&
                bonusAsPercent > 0 ? (
                  <CardDataBlockBonus>
                    <CardDataLabel>
                      <ExpressBonusLabel>
                        {t('bonus')}
                        <ExpressBonusModal>
                          <StyledBonusIconWrapper>
                            <IconInfoCircleSFilled />
                          </StyledBonusIconWrapper>
                        </ExpressBonusModal>
                      </ExpressBonusLabel>
                    </CardDataLabel>

                    <ExpressBonusContent>
                      <ExpressBonusPercent>
                        <IconPolygonBgLeftPart />
                        <ExpressBonusPercentContent>
                          {bonusAsPercent}%
                        </ExpressBonusPercentContent>
                        <IconPolygonBgLeftPart twist={180} />
                      </ExpressBonusPercent>
                    </ExpressBonusContent>
                  </CardDataBlockBonus>
                ) : null}
              </>
            ) : null}

            <CardDataBlockTag justifyContent="flex-end">
              <BetHistoryTagLegacy status={status} />
              {!hideBetAmount && <WonAmount>{betAmountFormatted}</WonAmount>}
            </CardDataBlockTag>

            {betType !== BetType.Ordinar ? (
              <CollapseCardIcon size={16} twist={isCollapsed ? 180 : 0} />
            ) : (
              <StyledEmptySpace />
            )}
          </CardDataFixedContainer>
        </CardMain>

        {(!isCollapsed || betType === BetType.Ordinar) && (
          <CardTable>
            <BetsHistoryHeader />

            <BetsHistoryOutcomesTable outcomes={outcomes} />
          </CardTable>
        )}
      </CardWrapper>
    </CollapsedWrapper>
  )
}
