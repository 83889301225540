import React, { FC, useCallback, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { useClickOutside } from 'shared/lib/click-outside/click-outside'

import { ITooltipProps, RefRectType } from './Tooltip.types'
import { StyledSpan, StyledTooltip, StyledTooltipBody } from './Tooltip.styled'
import { useBasketTooltipContext } from './basket-tooltip'

export const Tooltip: FC<ITooltipProps> = ({
  children,
  title,
  isCommon,
  isClick = false,
  placement = 'center',
  topPlacement = false,
  positionDelta = null,
  maxWidth = 0
}) => {
  const [isShow, setIsShow] = useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [refRect, setRefRect] = useState<RefRectType>({
    left: 0,
    top: 0,
    maxWidth: 400,
    translateX: 0
  })
  const handleClickOutside = useCallback(() => {
    if (isShow && isClick) {
      setIsShow(false)
    }
  }, [isClick, isShow])
  const refClickOutside = useClickOutside<HTMLDivElement>(handleClickOutside)

  const basketContext = useBasketTooltipContext()

  const getMaxTooltipWidth = useCallback(
    (target) => {
      const tooltip: DOMRect = target.getBoundingClientRect()
      const rect = basketContext || tooltip

      setRefRect({
        top: tooltip.y + (topPlacement ? -tooltip.height : tooltip.height) + 8,
        left:
          placement === 'center'
            ? rect.x + rect.width / 2
            : placement === 'left'
            ? rect.x
            : rect.x + rect.width,
        maxWidth: maxWidth || (basketContext ? rect.width : 400),
        translateX:
          placement === 'center' ? -50 : placement === 'left' ? 0 : -100
      })
    },
    [basketContext, placement, topPlacement]
  )

  const handleOnMouseEnter = (e: React.MouseEvent) => {
    if (!isClick) {
      getMaxTooltipWidth(e.currentTarget)
      timeoutRef.current = setTimeout(() => {
        setIsShow(true)
      }, 300)
    }
  }

  const handleOnMouseLeave = () => {
    if (!isClick) {
      clearTimeout(timeoutRef.current)
      setIsShow(false)
    }
  }

  const handleOnClick = (e: React.MouseEvent) => {
    if (isClick) {
      getMaxTooltipWidth(e.currentTarget)
      setIsShow((prev) => !prev)
    }
  }

  const handleOnWheel = () => {
    setIsShow(false)
  }

  return isCommon ? (
    <>{children}</>
  ) : (
    <StyledTooltip
      ref={refClickOutside}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onWheel={handleOnWheel}
    >
      {children}
      {isShow &&
        createPortal(
          // eslint-disable-next-line react/no-danger
          <StyledTooltipBody
            positionDelta={positionDelta}
            rect={refRect}
            topPlacement={topPlacement}
          >
            {typeof title === 'string' ? (
              <StyledSpan dangerouslySetInnerHTML={{ __html: title }} />
            ) : (
              title
            )}
          </StyledTooltipBody>,
          document.body
        )}
    </StyledTooltip>
  )
}
