import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  EventDayTagType,
  ETagsTop,
  getEntityTagName,
  getEventStatusBySport,
  selectEntityTags
} from 'entities/event'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const useGetEntityTagName = (
  props: Pick<Parameters<typeof getEntityTagName>[0], 'tags'>
) => {
  const entityTags = useAppSelector(selectEntityTags)

  return getEntityTagName(props, entityTags)
}

type EntityTagResult = {
  statusName: string
  status: ETagsTop | EventDayTagType
} | null

export const useGetEntityTagDictionary = (props: {
  tags: Pick<Parameters<typeof getEntityTagName>[0], 'tags'>
  sportCode: ESportsCodes
}): EntityTagResult => {
  const { tags, sportCode } = props
  const { t } = useTranslation()
  const entityTags = useAppSelector(selectEntityTags)

  return useMemo(() => {
    const entityTagName = getEntityTagName(tags, entityTags)

    if (!entityTagName) {
      return null
    }

    if (entityTagName === ETagsTop.TOP || entityTagName === ETagsTop.SUPERTOP) {
      return {
        statusName: entityTagName,
        status: entityTagName
      }
    }

    if (entityTagName === ETagsTop.EVENT_DAY) {
      const eventStatus = getEventStatusBySport(sportCode)
      if (!eventStatus) {
        return null
      }

      return {
        statusName: t(eventStatus.toLowerCase()),
        status: eventStatus
      }
    }

    return null
  }, [tags, sportCode, entityTags, t])
}
