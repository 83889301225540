import styled, { css } from 'styled-components'
import { BetStatus } from 'betweb-openapi-axios'

import { typography } from 'shared/lib/theme/typography'
import { customMedia } from 'shared/lib/styled'
import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

export const CollapsedWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
  `}
`

export const CardMain = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`

export const CardWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const CardTable = styled.table`
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;

  ${customMedia.tabletOrMobile} {
    table-layout: auto;
  }
`

export const TableColumn = styled.th`
  ${({ theme }) => css`
    padding-bottom: ${theme.tokens.alias.space['space-xs-8']}px;
  `};
`

export const MainInfoBlock = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;

  max-width: 465px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const CardName = styled.div`
  ${typography['Body / 12 SemiBold']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const CardDate = styled.div`
  ${typography['Body / 12 Medium']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const CardDataFixedContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 30%;
  justify-content: flex-end;
  min-width: 240px;

  ${customMedia.small} {
    height: 36px;
  }

  ${customMedia.medium} {
    flex: 0 0 32%;
  }

  ${customMedia.large} {
    flex: 0 0 32%;
  }
`

export const CardDataLabel = styled.span`
  ${typography['Body / 12 Regular']};
  white-space: nowrap;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-description']};
  `}
`

export const CardDataValue = styled.span`
  ${typography['Body / 12 Medium']};
  align-items: center;
  display: inline-flex;
  white-space: nowrap;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const CardDataAmountValue = styled(CardDataValue)`
  white-space: nowrap;
`

export const CardDataBlock = styled.div<{
  justifyContent?: 'flex-start' | 'flex-end' | 'space-between'
}>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  ${({ justifyContent = 'flex-start' }) => css`
    justify-content: ${justifyContent};
  `}
`

export const CardDataBlockName = styled.div`
  align-items: flex-start;
`

export const CardDataBlockCashout = styled.div`
  flex-shrink: 0;

  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const CardDataBlockAmount = styled(CardDataBlock)`
  flex: 1 1 auto;

  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-s-12']}px;
  `}

  ${customMedia.tabletOrMobile} {
    width: 104px;
  }

  ${customMedia.medium} {
    width: 190px;
  }

  ${customMedia.large} {
    width: 190px;
  }
`

export const CardDataBlockOdd = styled(CardDataBlock)`
  flex: 0 0 98px;
  min-width: 92px;

  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
  `}

  ${customMedia.medium} {
    flex: 0 0 150px;
  }

  ${customMedia.large} {
    flex: 0 0 150px;
  }
`

export const CardDataBlockBonus = styled(CardDataBlock)`
  align-items: flex-start;
  flex-shrink: 0;

  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const CardDataBlockTag = styled(CardDataBlock)`
  flex: 1 0 auto;
  max-width: 300px;

  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px 0
      ${theme.tokens.alias.space['space-s-12']}px;
  `}

  ${customMedia.medium} {
    max-width: none;
  }

  ${customMedia.large} {
    max-width: none;
  }
`

export const StyledEmptySpace = styled.div`
  height: 16px;
  width: 16px;
`

export const BetHistoryTagWrapper = styled.div<{ status: BetStatus }>`
  ${typography['Hint / 10 BOLD']};
  ${({ theme, status }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
    padding: 0 ${theme.tokens.alias.space['space-xxs-6']}px;

    ${status === BetStatus.Current &&
    css`
      background-color: #6c7993;
      color: ${theme.tokens.colors.text['text-invert']};
    `}

    ${status === BetStatus.Won &&
    css`
      background-color: #45b82e;
      color: ${theme.tokens.colors.text['text-invert']};
    `}

    ${status === BetStatus.Returned &&
    css`
      background-color: #ffbf00;
      color: ${theme.tokens.colors.text['text-invert']};
    `}

    ${status === BetStatus.Lost &&
    css`
      background-color: #6c7993;
      color: ${theme.tokens.colors.text['text-invert']};
    `}

    ${status === BetStatus.Sold &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-tag']};
      color: ${theme.tokens.colors.text['text-invert']};
    `}
  `}
`

export const WonAmount = styled.div`
  ${typography['Body / 14 SemiBold']};
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const CollapseCardIcon = styled(IconChevronDown)`
  align-self: center;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-secondary-3']};

    &:hover {
      color: ${theme.tokens.colors.icon['icon-secondary']};
    }
    &:active {
      color: ${theme.tokens.colors.icon['icon-active']};
    }
  `}
`

export const ExpressBonusLabel = styled.div`
  align-items: center;
  display: flex;
  /* gap: 4px; */
`

export const ExpressBonusSum = styled.div`
  ${typography['Body / 12 Medium']};
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-warning']};
    display: flex;
    gap: 2px;
  `}
`

export const ExpressBonusPercent = styled.div`
  align-items: center;
  display: flex;
  height: 14px;
  overflow: hidden;
  padding: 0 3px;
  position: relative;

  & svg {
    color: #e5ac00;
  }

  & > span {
    height: 14px;
    position: absolute;
    width: 3.4px;

    &:first-child {
      left: 0.4px;
    }

    &:last-child {
      right: 0.4px;
    }
  }
`

export const ExpressBonusContent = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`

export const ExpressBonusPercentContent = styled.div`
  ${typography['Hint / 10 EXTRABOLD']};
  ${({ theme }) => css`
    background: #e5ac00;
    color: ${theme.tokens.colors.text['text-invert']};
  `}
`

export const StyledBonusIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`
