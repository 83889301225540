import { FC } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { LiveContentProps } from './live-content.types'
import { LiveScoreCell, LiveStatsCell } from './ui'

export const LiveContent: FC<LiveContentProps> = ({ event, sportName }) => {
  return (
    <>
      <LiveScoreCell
        eventStatistics={event.statistics}
        sportName={sportName as ESportsCodes}
      />
      <LiveStatsCell event={event} />
    </>
  )
}
