import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  EntityLogoList,
  LogosApi,
  LogosApiGetLogosRequest
} from 'betweb-openapi-axios/dist/api'
import { ApiManager } from 'astra-core/api'

import { RootState } from 'shared/types/store'
import { selectLogosContainerState } from 'entities/logos/selectors'

export const getLogos = createAsyncThunk<
  EntityLogoList,
  LogosApiGetLogosRequest
>('getLogos', async (payload, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const logosApi = ApiManager.getApi(LogosApi)
  const logosContainerState = selectLogosContainerState(state)

  const idsToRequest = payload.ids.filter(
    (id) => !logosContainerState.requested[payload.entityType][id]
  )

  if (!idsToRequest.length) {
    return { entityType: payload.entityType, items: [] }
  }

  const { data } = await logosApi.getLogos(payload)

  return data
})
