import styled, { css } from 'styled-components'

import { ETagMaxColor } from './types'

export const MaxTagContainer = styled.div<{ iconColor: ETagMaxColor }>`
  align-items: center;
  display: flex;
  justify-content: center;

  & > span {
    width: 27px;
  }

  ${({ theme, iconColor }) => css`
    ${iconColor === ETagMaxColor.GREEN &&
    css`
      & svg {
        color: ${theme.tokens.colors.surface['surface-brand-default']};
      }
    `}

    ${iconColor === ETagMaxColor.GRAY &&
    css`
      & svg {
        color: ${theme.tokens.colors.surface['surface-active-3']};
      }
    `}
  `}
`
