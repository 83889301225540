import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsStatus,
  selectBetsHistoryFiltersDates,
  selectBetsHistoryPageValues,
  SetDatePickerRangeValuesPayload
} from 'astra-core/containers/BetsHistoryProvider'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { BetStatus } from 'betweb-openapi-axios'
import dayjs from 'dayjs'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { Pagination } from 'astra-core/typings/api'
import keyBy from 'lodash/keyBy'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  betsHistoryApi,
  LegacyBet,
  resetBetsHistory
} from 'entities/legacy-api'
import { useFetchBetsHistoryPage } from 'pages/page-bets-history/BetsHistoryPageContainer/hooks'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'
import { PickerPeriod } from 'features/picker-period'
import { getIsResetPagination } from 'widgets/bets-history-legacy/components/History/constants'

import { BETS_HISTORY_PAGE_SIZE } from '../lib'
import { BasketHistoryCard } from '../../ui/BasketHistoryCard'

import { SelectBetStatus } from './SelectBetStatus'
import {
  StyledBasketHistoryFilters,
  StyledBasketHistoryList,
  StyledBasketHistoryScrollable,
  StyledBasketHistoryWrapper,
  StyledListWrapper,
  StylesBasketHistoryContent
} from './BasketHistory.styled'
import { EmptyHistoryScreen } from './EmptyHistoryScreen'
import { BasketHistoryCardLegacy } from './BasketHistoryCard'

const { useGetBetsHistoryQuery } = betsHistoryApi

export const BasketHistoryLegacy = () => {
  const isCashoutEnabled = useFeatureFlag(EFeatureFlags.CASHOUT_ENABLED)
  const dispatch = useAppDispatch()
  useFetchBetsHistoryPage()

  useEffect(() => {
    if (isCashoutEnabled) {
      dispatch(betsHistoryProviderActions.subscribeCashoutSSE())

      return () => {
        dispatch(betsHistoryProviderActions.unsubscribeCashoutSSE())
      }
    }
  }, [dispatch, isCashoutEnabled])

  useEffect(() => {
    return () => {
      dispatch(betsHistoryProviderActions.resetBetsHistory())
    }
  }, [dispatch])

  const [pagination, setPagination] = useState({
    limit: BETS_HISTORY_PAGE_SIZE,
    offset: 0
  })
  const [betsStatus, setBetsStatus] = useState<BetStatus>(BetStatus.Unknown)
  const dates = useAppSelector(selectBetsHistoryFiltersDates)

  const handleResetPagination = () => {
    handleSetPagination({
      offset: 0,
      limit: BETS_HISTORY_PAGE_SIZE
    })
  }

  const filter = useMemo(() => {
    switch (betsStatus) {
      case BetStatus.Computed:
        return '100000'
      case BetStatus.Current:
        return '010000'
      case BetStatus.Won:
        return '001000'
      case BetStatus.Lost:
        return '000100'
      case BetStatus.Returned:
        return '000010'
      default:
        return '000000'
    }
  }, [betsStatus])

  const { data, isFetching } = useGetBetsHistoryQuery({
    time_shift: 0,
    filter,
    ...pagination,
    date:
      dates.startDate && dates.endDate
        ? dayjs(dates.startDate).format('DD-MM-YYYY')
        : undefined,
    date_end:
      dates.startDate && dates.endDate
        ? dayjs(dates.endDate).format('DD-MM-YYYY')
        : undefined,
    offset: pagination.offset / BETS_HISTORY_PAGE_SIZE
  })

  useEffect(() => {
    return () => {
      dispatch(resetBetsHistory())
    }
  }, [dispatch])

  const betsList = data?.data.bet_list ?? []
  const totalCount = data?.data.count ?? 0

  const hasMore = totalCount > betsList.length

  const handleSetPagination = (newPagination: Pagination) => {
    dispatch(betsHistoryProviderActions.setPagination(newPagination))
    setPagination(newPagination)
  }

  const handleBetStatusChange = (newStatus: BetStatus) => {
    // Set new status
    setBetsStatus(newStatus)
    dispatch(betsHistoryProviderActions.setFilterBetStatus(newStatus))

    // Reset pagination
    handleResetPagination()
  }

  const handleSetBetsDateRange = (
    dateList: SetDatePickerRangeValuesPayload
  ) => {
    if (getIsResetPagination(dateList)) {
      handleResetPagination()
    }
  }

  return (
    <StyledBasketHistoryWrapper>
      <StyledBasketHistoryFilters>
        <SelectBetStatus value={betsStatus} onChange={handleBetStatusChange} />
        <PickerPeriod
          onChangeDatePicker={handleSetBetsDateRange}
          onChangeSelect={handleSetBetsDateRange}
        />
      </StyledBasketHistoryFilters>

      {isFetching && pagination.offset === 0 ? (
        <LoaderSpinner />
      ) : (
        <StyledBasketHistoryScrollable>
          <StylesBasketHistoryContent>
            <StyledListWrapper
              pagination={{
                pagination,
                onSetPagination: handleSetPagination,
                hasMore,
                isLoading: isFetching
              }}
            >
              <BasketHistoryList bets={betsList} />
            </StyledListWrapper>
          </StylesBasketHistoryContent>
        </StyledBasketHistoryScrollable>
      )}
    </StyledBasketHistoryWrapper>
  )
}

export const BasketHistoryList = ({ bets }: { bets: LegacyBet[] }) => {
  const betsStatus = useAppSelector(selectBetsHistoryBetsStatus)

  const betsListLength = bets.length

  return (
    <>
      {betsListLength ? (
        <StyledBasketHistoryList>
          {bets.map((item, index) => (
            <BasketHistoryCardWrapper
              bet={item}
              isLastElement={index === betsListLength - 1}
              key={item.bet_id}
            />
          ))}
        </StyledBasketHistoryList>
      ) : (
        <EmptyHistoryScreen withBetStatus={betsStatus !== null} />
      )}
    </>
  )
}

const BasketHistoryCardWrapper: FC<{
  bet: LegacyBet
  isLastElement: boolean
}> = ({ bet, isLastElement }) => {
  const [platform, id] = bet.cardnumber.split(' ')
  const betsListTableData = useAppSelector(selectBetsHistoryPageValues)
  const platformBetsHistoryEntries = useMemo(
    () => keyBy(betsListTableData, 'id'),
    [betsListTableData]
  )

  const isPlatformBet = platform === 'Astrabet' && !!id

  return isPlatformBet && platformBetsHistoryEntries[id] ? (
    <BasketHistoryCard
      isLastElement={isLastElement}
      item={platformBetsHistoryEntries[id]}
      number={bet.bet_id}
    />
  ) : (
    <BasketHistoryCardLegacy isLastElement={isLastElement} item={bet} />
  )
}
