import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { FC, Fragment, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getEventStatusBySport } from 'entities/event'
import { useHotProbsMarketHeaderConfig } from 'features/events-table'
import { useAppSelector } from 'shared/lib/@reduxjs'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { Tooltip } from 'shared/ui/Tooltip'
import { Typography } from 'shared/ui/typography'

import * as S from './event-table-header.styled'
import { EventTableHeaderProps } from './event-table-header.types'

export const EventTableHeader: FC<EventTableHeaderProps> = memo(
  ({
    withIcon,
    backLinkBuilder,
    hideOutcomeCodes,
    isHideHeader,
    tournamentSportId,
    tournamentName,
    isDayEvent
  }) => {
    const { t } = useTranslation()

    const sport = useAppSelector((state) =>
      selectSport(state, tournamentSportId)
    )
    const dayEventName = getEventStatusBySport(sport?.code as ESportsCodes)

    const [nameRef, isNameOverflowing] = useTextOverflow<HTMLDivElement>()
    const config = useAppSelector(selectConfig)

    const { headerConfig } = useHotProbsMarketHeaderConfig({
      sportId: tournamentSportId
    })

    return (
      <S.HeaderWrapper hideHeader={config.IS_IFRAME || !!isHideHeader}>
        <S.StyledTitleCell colSpan={3}>
          <S.MainInfoWrapper>
            <S.MainInfoIconsBlock>
              {backLinkBuilder && (
                <S.BackButton
                  to={backLinkBuilder(tournamentSportId, sport.genericSportId)}
                >
                  <IconChevronLeft colorToken="icon-secondary-2" />
                </S.BackButton>
              )}
              <HeaderIcon
                isDayEvent={isDayEvent}
                sportCode={sport?.code as ESportsCodes}
                withIcon={withIcon}
              />
            </S.MainInfoIconsBlock>
            <HeaderText
              dayEventName={dayEventName}
              isDayEvent={isDayEvent}
              isNameOverflowing={isNameOverflowing}
              nameRef={nameRef}
              tournamentName={tournamentName}
            />
          </S.MainInfoWrapper>
        </S.StyledTitleCell>

        <S.StyledBlocksCell colSpan={2}>
          <S.StyledBlocksWrapper>
            {headerConfig?.map((block) => (
              <S.BlockWrapper isMerged={block.isMerged} key={block.id}>
                {block.config.map((item) => (
                  <Fragment key={item.localeKey || item.label}>
                    {!hideOutcomeCodes ? (
                      <S.HeaderCell customWidth={item.customWidth}>
                        {item.localeKey ? t(item.localeKey) : item.label}
                      </S.HeaderCell>
                    ) : (
                      <S.HeaderCell customWidth={item.customWidth} />
                    )}
                  </Fragment>
                ))}
              </S.BlockWrapper>
            ))}
          </S.StyledBlocksWrapper>
        </S.StyledBlocksCell>
      </S.HeaderWrapper>
    )
  }
)

const HeaderIcon: FC<{
  isDayEvent?: boolean
  withIcon?: boolean
  sportCode?: ESportsCodes
}> = memo(({ isDayEvent, withIcon, sportCode }) => {
  if (!isDayEvent && (!withIcon || !sportCode)) {
    return null
  }

  return (
    <S.SportIconWrapper>
      {isDayEvent ? (
        <IconTop colorToken="icon-day-event" />
      ) : (
        <SportIcon size="s" sport={sportCode} />
      )}
    </S.SportIconWrapper>
  )
})

HeaderIcon.displayName = 'HeaderIcon'

const HeaderText: FC<{
  isNameOverflowing: boolean
  isDayEvent?: boolean
  dayEventName?: string | null
  tournamentName?: string
  nameRef: React.RefObject<HTMLDivElement>
}> = memo(
  ({
    isNameOverflowing,
    isDayEvent,
    dayEventName,
    tournamentName,
    nameRef
  }) => {
    const { t } = useTranslation()

    const displayText = useMemo(
      () =>
        isDayEvent && dayEventName
          ? t(dayEventName.toLowerCase())
          : tournamentName,
      [isDayEvent, dayEventName, tournamentName, t]
    )

    return (
      <Tooltip
        isCommon={!isNameOverflowing}
        placement="left"
        title={tournamentName}
      >
        <Typography
          color="text-secondary-2"
          font="Body / 12 Medium"
          lineClamp={1}
          ref={nameRef}
        >
          {displayText}
        </Typography>
      </Tooltip>
    )
  }
)

HeaderText.displayName = 'HeaderText'
