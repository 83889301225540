import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsStatus,
  selectBetsHistoryDataBetsFetchItems,
  selectBetsHistoryPageValues
} from 'astra-core/containers/BetsHistoryProvider'
import {
  useFeatureFlag,
  EFeatureFlags
} from 'astra-core/containers/ConfigProvider'
import { useEffect } from 'react'

import { useFetchBetsHistoryPage } from 'pages/page-bets-history/BetsHistoryPageContainer/hooks'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'
import { PickerPeriod } from 'features/picker-period'

import { PAGINATION_SETTINGS } from '../../lib'
import { SelectBetStatus } from '../SelectBetStatus'
import { BasketHistoryCard } from '../BasketHistoryCard'
import { EmptyHistoryScreen } from '../EmptyHistoryScreen'

import {
  StyledBasketHistoryFilters,
  StyledBasketHistoryList,
  StyledBasketHistoryScrollable,
  StyledBasketHistoryWrapper,
  StyledListWrapper,
  StylesBasketHistoryContent
} from './basket-history.styled'

export const BasketHistory = () => {
  const betsListTableData = useAppSelector(selectBetsHistoryPageValues)
  const betsFetchItems = useAppSelector(selectBetsHistoryDataBetsFetchItems)
  const isCashoutEnabled = useFeatureFlag(EFeatureFlags.CASHOUT_ENABLED)
  const dispatch = useAppDispatch()

  useFetchBetsHistoryPage()

  useEffect(() => {
    if (isCashoutEnabled) {
      dispatch(betsHistoryProviderActions.subscribeCashoutSSE())

      return () => {
        dispatch(betsHistoryProviderActions.unsubscribeCashoutSSE())
      }
    }
  }, [dispatch, isCashoutEnabled])

  useEffect(() => {
    return () => {
      dispatch(betsHistoryProviderActions.resetBetsHistory())
    }
  }, [dispatch])

  return (
    <StyledBasketHistoryWrapper>
      <StyledBasketHistoryFilters>
        <SelectBetStatus />
        <PickerPeriod />
      </StyledBasketHistoryFilters>

      {betsListTableData.length ? (
        <BasketHistoryList />
      ) : (
        <>
          {betsFetchItems.loading ? <LoaderSpinner /> : <BasketHistoryList />}
        </>
      )}
    </StyledBasketHistoryWrapper>
  )
}

export const BasketHistoryList = () => {
  const betsStatus = useAppSelector(selectBetsHistoryBetsStatus)
  const betsListTableData = useAppSelector(selectBetsHistoryPageValues)

  const betsListLength = betsListTableData.length

  return (
    <StyledBasketHistoryScrollable>
      <StylesBasketHistoryContent>
        <StyledListWrapper pagination={PAGINATION_SETTINGS}>
          {betsListLength ? (
            <StyledBasketHistoryList>
              {betsListTableData.map((item, index) => (
                <BasketHistoryCard
                  isLastElement={index === betsListLength - 1}
                  item={item}
                  key={item.id}
                />
              ))}
            </StyledBasketHistoryList>
          ) : (
            <EmptyHistoryScreen withBetStatus={betsStatus !== null} />
          )}
        </StyledListWrapper>
      </StylesBasketHistoryContent>
    </StyledBasketHistoryScrollable>
  )
}
