import styled, { css } from 'styled-components'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'

export const EventsTableRowPropsLoader = () => {
  return (
    <RowRight>
      <ContentLoader borderRadius={4} height={32} width={112} />
      <ContentLoader borderRadius={4} height={32} width={112} />
      <ContentLoader borderRadius={4} height={32} width={146} />
      <ContentLoader borderRadius={4} height={32} width={118} />
      <ContentLoader borderRadius={4} height={32} width={52} />
    </RowRight>
  )
}

const RowRight = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`
