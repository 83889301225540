import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { StyledTextInputProps } from 'shared/ui/text-input/text-input.types'
import { typography } from 'shared/lib/theme/typography'

import { FontName } from '../../lib/theme'

export const StyledTextInputWrapper = styled.div`
  display: grid;
  width: 100%;
`

export const StyledLabelText = styled.label`
  ${typography['Body / 14 Medium']}
  left: 12px;
  position: absolute;
  right: 12px;
  top: 10px;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-description']};
  `}

  transition: 0.25s ease;
`

const activeInput = css`
  ${({ theme }) => css`
    ${StyledLabelText} {
      ${typography['Hint / 11 Medium']}
      color: ${theme.tokens.colors.text['text-secondary-3']};
      top: 2px;
    }
  `}
`

const activeInputAuthForm = css`
  ${() => css`
    ${StyledLabelText} {
      top: 6px;
    }

    ${StyledInternalInput} {
      font-size: 14px;
      padding-bottom: 5px;
    }
  `}
`

export const StyledTextInput = styled.div<StyledTextInputProps>`
  cursor: text;
  position: relative;
  transition: background-color 0.25s ease, box-shadow 0.25s;

  ${({
    theme,
    size,
    background,
    hasValue,
    hasLabel,
    isDisabled,
    isError,
    isAuthForm
  }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    outline: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    outline-offset: -1px;

    ${size === 's' &&
    css`
      height: 32px;
    `}
    ${size === 'm' &&
    css`
      height: 40px;
    `}
    ${size === 'l' &&
    css`
      height: 48px;
    `}
    
    ${background === 'white' &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-active']};
    `}
    
    ${background === 'gray' &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-default']};
    `}

    ${background === 'yellow' &&
    css`
      background-color: ${theme.typeLayoutTheme === LayoutTheme.Light
        ? '#fff9e5'
        : '#332600'} !important;
      outline-color: ${theme.typeLayoutTheme === LayoutTheme.Light
        ? '#ffbf0066'
        : '#FFBF0040'};
    `}

    ${background === 'purple' &&
    css`
      background-color: #f2edfd !important;
      outline-color: #c9b4f6;
    `}
    
    ${hasValue &&
    css`
      ${activeInput}
      ${isAuthForm && activeInputAuthForm}
      ${background === 'white' &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-active']};
      `}

      ${background === 'gray' &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-default']};
      `}
    `}

    ${!hasLabel &&
    css`
      ${size === 's' &&
      css`
        && ${StyledInternalInputWrapper} {
          bottom: 6px;
        }
      `}
      ${size === 'm' &&
      css`
        && ${StyledInternalInputWrapper} {
          bottom: 10px;
        }
      `}
    `}


    &&:focus, &&:focus-within {
      ${activeInput};
      ${isAuthForm && activeInputAuthForm}

      background-color: ${theme.tokens.colors.surface['surface-active']};
      outline-color: ${theme.tokens.colors.border['border-active']};
    }

    ${isError &&
    css`
      outline-color: ${theme.tokens.colors.border['border-error']} !important;
    `}

    &:hover {
      outline-color: ${theme.tokens.colors.border['border-settings-active']};
    }

    ${isDisabled &&
    css`
      outline-color: ${theme.tokens.colors.border['border-disabled']};

      ${background === 'white' &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-base']};
      `}

      ${background === 'gray' &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-disabled']};
      `}
      
      pointer-events: none;

      ${StyledInternalInput} {
        &::placeholder {
          color: ${theme.tokens.colors.text['text-disabled']};
        }
      }

      ${StyledStartAdornment}>div {
        color: ${theme.tokens.colors.text['text-disabled']};
      }

      ${hasValue
        ? css`
            ${activeInput};
            ${isAuthForm && activeInputAuthForm}
            outline: none;
          `
        : css`
            ${StyledLabelText} {
              color: ${theme.tokens.colors.text['text-disabled']};
            }
          `}
    `}

    ${StyledLabelText} {
      ${!!isAuthForm &&
      !hasValue &&
      css`
        top: 14px;
      `}
    }
  `}
`

export const StyledInternalInputWrapper = styled.div`
  align-items: center;
  bottom: 2px;
  display: flex;
  height: 20px;
  left: 12px;
  position: absolute;
  right: 12px;
`

export const StyledInternalInput = styled.input<{
  font: FontName
}>`
  background-color: transparent;
  border: none;
  flex-grow: 1;
  margin: 0;
  outline: none;
  padding: 0;
  width: calc(100% - 8px);

  ${({ theme, font }) => css`
    ${typography[font]};
    color: ${theme.tokens.colors.text['text-primary-2']};

    &::placeholder {
      color: ${theme.tokens.colors.text['text-description']};
    }
  `}
`

export const StyledMaskPlaceholder = styled.span<{
  font: FontName
}>`
  bottom: 2px;
  height: 20px;
  left: 12px;
  position: absolute;
  right: 12px;

  ${({ theme, font }) => css`
    ${typography[font]};
    color: ${theme.tokens.colors.text['text-description']};
  `}
`

export const StyledErrorText = styled.div`
  ${typography['Body / 12 Medium']}
  white-space: break-spaces;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-error']};
  `}
`

export const StyledDescriptionWrapper = styled.div`
  ${typography['Body / 12 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`

export const RenderRightWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
`

export const StyledStartAdornment = styled.div`
  display: flex;
`

export const TooltipWrapper = styled.div`
  margin-top: 1rem;
`
