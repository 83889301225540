import { FC } from 'react'
import { useParams } from 'react-router-dom'

import {
  selectEventsLayoutIsLoading,
  selectLineDayEvents
} from 'entities/line-tournaments/model/selectors'
import { LineParams } from 'entities/line/model/types'
import { useAppSelector } from 'shared/lib/@reduxjs'
import { RootState } from 'shared/types/store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { EventsTable } from 'widgets/events-table'

export const TopLineEvents: FC = () => {
  const { tournamentId } = useParams<LineParams>()
  const eventDayEvents = useAppSelector((state: RootState) =>
    selectLineDayEvents(state, tournamentId)
  )
  const isLayoutEventsLoading = useAppSelector(selectEventsLayoutIsLoading)

  return (
    <>
      {isLayoutEventsLoading ? (
        <LoaderSpinner />
      ) : eventDayEvents.length ? (
        <EventsTable events={eventDayEvents} isDayEvent />
      ) : (
        <></>
      )}
    </>
  )
}
