import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { typography } from 'shared/lib/theme/typography'

export const BetStatusWaitWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const BetStatusWaitText = styled.div`
  ${typography['Body / 16 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const BetStatusWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xl-24']}px;
  `}
`

export const BetStatusContent = styled.div<{ appliedCoupon?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme, appliedCoupon }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;

    ${appliedCoupon &&
    css`
      & svg {
        color: #7845e7;
      }
    `}
  `}
`

export const BetStatusBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const BetStatusTitle = styled.div`
  ${typography['Body / 16 SemiBold']}
  text-align: center;

  ${({ theme }) =>
    css`
      color: ${theme.tokens.colors.text['text-primary']};
    `}
`

export const BetStatusText = styled.div<{ appliedCoupon?: boolean }>`
  ${typography['Body / 14 Medium']}
  max-width: 268px;
  text-align: center;

  ${({ theme, appliedCoupon }) =>
    css`
      color: ${theme.tokens.colors.text['text-secondary-3']};

      ${appliedCoupon &&
      css`
        display: flex;
        gap: 4px;
      `}
    `}
`

export const StyledLink = styled(NavLink)`
  display: block;
  ${typography['Body / 14 Medium']}
  ${({ theme }) =>
    css`
      color: ${theme.tokens.colors.text['text-linkAccent-default']};
    `}
`
