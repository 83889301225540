import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  basketProviderActions,
  selectBasketAppliedCoupon,
  selectBasketBonusWallet
} from 'astra-core/containers/BasketProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { ERoutes } from 'shared/types/routes'
import { Button } from 'shared/ui/Button'
import { IconWarningCirle } from 'shared/ui/Icon/General/IconWarningCircle'
import { LoaderLogo } from 'shared/ui/LoaderLogo'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  BetStatusBox,
  BetStatusContent,
  BetStatusText,
  BetStatusTitle,
  BetStatusWaitText,
  BetStatusWaitWrapper,
  BetStatusWrapper,
  StyledLink
} from './BasketStatusScreens.styled'

export const BasketStatusWait: FC = () => {
  const [t] = useTranslation()

  return (
    <BetStatusWaitWrapper>
      <LoaderLogo minimized />
      <BetStatusWaitText>{t('the bet is accepting')}</BetStatusWaitText>
    </BetStatusWaitWrapper>
  )
}

export const BasketStatusSuccess: FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)
  const bonusWallet = useAppSelector(selectBasketBonusWallet)
  const { IS_IFRAME } = useAppSelector(selectConfig)

  // eslint-disable-next-line unused-imports/no-unused-vars
  const historySelect = useMemo(
    () => ({
      betsHistory: () =>
        IS_IFRAME
          ? window.parent.location.replace(
              'https://olimpbet.kz/index.php?page=history'
            )
          : history.push(ERoutes.BetsHistory)
    }),
    [history, IS_IFRAME]
  )

  const title = appliedCoupon
    ? 'bonus coupon bet has been accepted'
    : bonusWallet
    ? 'bonus wallet bet has been accepted'
    : 'your bet is accepted'

  return (
    <BetStatusWrapper>
      <BetStatusContent appliedCoupon={!!appliedCoupon}>
        <IconSuccess
          colorToken={bonusWallet ? 'icon-warning' : 'icon-positive'}
          size={40}
        />
        <BetStatusBox>
          <BetStatusTitle>{t(title)}</BetStatusTitle>
          <BetStatusText appliedCoupon={!!appliedCoupon}>
            <Trans
              i18nKey={
                appliedCoupon
                  ? 'go to bet history'
                  : 'the results can be found in'
              }
              components={[<StyledLink to={ERoutes.BetsHistory} />]}
              t={t}
            />
          </BetStatusText>
        </BetStatusBox>
      </BetStatusContent>
    </BetStatusWrapper>
  )
}

export const BasketStatusError: FC = () => {
  const [t] = useTranslation()

  const dispatch = useAppDispatch()

  const handleReset = useCallback(() => {
    dispatch(basketProviderActions.resetState())
  }, [dispatch])

  return (
    <BetStatusWrapper>
      <BetStatusContent>
        <IconWarningCirle size={40} />
        <BetStatusBox>
          <BetStatusTitle>{t('something went wrong no smile')}</BetStatusTitle>
          <BetStatusText>
            {t('please try placing your bet again')}
          </BetStatusText>
        </BetStatusBox>
      </BetStatusContent>
      <Button view="secondary-large" onClick={handleReset}>
        {t('retry the attempt')}
      </Button>
    </BetStatusWrapper>
  )
}
