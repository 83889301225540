import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledAvailableCoupons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledAvailableCouponsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const StyledAvailableCouponsSingle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const StyledBasketAvailableCouponWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  padding: 8px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-modal']};
    outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    outline-offset: -1px;
  `}
`

export const StyledBonusText = styled.div`
  ${typography['Body / 12 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
  flex-grow: 1;
`

export const StyledAvailableCouponInfo = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 4px;
`

export const StyledAvailableCouponHide = styled.div`
  ${typography['Hint / 10 SemiBold']}
  color: #7845E7;
  cursor: pointer;
  text-align: center;
`

export const StyledAvailableCouponsOpen = styled.div`
  ${typography['Hint / 10 SemiBold']}
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  line-height: 16px;
  margin-top: -1px;
  text-align: center;
  width: 260px;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-modal']};
    color: #7845e7;
    outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    outline-offset: -1px;
  `}

  &:hover {
    animation: adjust-padding 0.3s linear forwards;
  }

  @keyframes adjust-padding {
    0% {
      padding: 0px 0px 0px;
    }
    25% {
      padding: 1px 0px 0px;
    }
    50% {
      padding: 1px 0px 1px;
    }
    75% {
      padding: 1px 0px 2px;
    }
    100% {
      padding: 1px 0px 3px;
    }
  }

  &:not(:hover) {
    animation: reverse-padding 0.3s linear forwards;
  }

  @keyframes reverse-padding {
    0% {
      padding: 1px 0px 3px;
    }
    25% {
      padding: 1px 0px 2px;
    }
    50% {
      padding: 1px 0px 1px;
    }
    75% {
      padding: 1px 0px 0px;
    }
    100% {
      padding: 0px 0px 0px;
    }
  }
`

export const StyledCouponModalInner = styled.div`
  padding: 0 32px;
`
