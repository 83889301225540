import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Event, MarketGroup } from 'betweb-openapi-axios'
import { selectMarketGroupsByEvent } from 'astra-core/containers/EventsProvider'

import { outcomesContainerActions } from 'containers/OutcomesContainer/slice'
import { selectOutcomesGroup } from 'containers/OutcomesContainer/selectors'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'
import { ControlButton } from 'shared/ui/Carousel/Carousel.styled'
import { IconArrowLeft } from 'shared/ui/Icon/General/IconArrowLeft'
import { IconArrowRight } from 'shared/ui/Icon/General/IconArrowRight'

import { OutcomesGroupsTab } from '../outcomes-filter-tab'

import {
  StyledTabScrollerContainer,
  StyledTabScrollerWrapper
} from './OutcomesGroupsTabs.styled'
import { ITabScroller } from './OutcomesGroupsTabs.types'

type OutcomesFilterTabsProps = {
  eventId: Event['id']
}

const NO_GROUP_SELECTED_VALUE = 'event-groups-all'

export const OutcomesGroupsTabs: FC<OutcomesFilterTabsProps> = ({
  eventId
}) => {
  const selectedGroup = useAppSelector(selectOutcomesGroup)
  const dispatch = useAppDispatch()
  const [t] = useTranslation()

  const marketGroups = useAppSelector((state) =>
    selectMarketGroupsByEvent(state, eventId)
  )

  useEffect(() => {
    return () => {
      dispatch(outcomesContainerActions.setSelectedOutcomesGroup(null))
    }
  }, [dispatch])

  const handleSportTypeClick = useCallback(
    (groupId: MarketGroup['id']) =>
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup(
          marketGroups.find((m) => m.id === groupId) ?? null
        )
      ),
    [dispatch, marketGroups]
  )

  const items = [
    { label: t('all'), value: NO_GROUP_SELECTED_VALUE },
    ...marketGroups.map((m) => ({
      value: m.id,
      label: m.name
    }))
  ]

  return (
    <TabScroller withFadeEffect>
      {items.map((item) => (
        <OutcomesGroupsTab
          item={item}
          key={item.value}
          value={selectedGroup?.id ?? NO_GROUP_SELECTED_VALUE}
          onChange={handleSportTypeClick}
        />
      ))}
    </TabScroller>
  )
}

export const TabScroller: FC<PropsWithChildren<ITabScroller>> = ({
  children,
  withFadeEffect = false,
  leftOffset = 0,
  rightOffset = 0,
  btnLeftOffset = 12,
  btnRightOffset = 12
}) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [scrollPos, setScrollPos] = useState(0)

  const handleScroll = () => {
    if (carouselRef.current) {
      const scrolledTo = Math.ceil(carouselRef.current.scrollLeft)
      setScrollPos(scrolledTo)
    }
  }

  const scrollLeftTo = (direction: -1 | 1) => {
    if (carouselRef.current) {
      const scrollTo = Math.round(
        carouselRef.current.scrollLeft +
          direction * carouselRef.current?.clientWidth * 0.25
      )

      setScrollPos(scrollTo)

      carouselRef.current.scrollTo({
        left: scrollTo,
        behavior: 'smooth'
      })
    }
  }

  const scrollWidth = useMemo(
    () =>
      (carouselRef?.current?.scrollWidth ?? 0) -
      (carouselRef?.current?.clientWidth ?? 0),
    /* eslint-disable-next-line */
    [carouselRef?.current?.clientWidth, carouselRef?.current?.scrollWidth]
  )

  return (
    <StyledTabScrollerContainer
      isScrollableToLeft={scrollPos > 0}
      isScrollableToRight={scrollPos < scrollWidth}
      withFadeEffect={withFadeEffect}
    >
      <StyledTabScrollerWrapper
        leftOffset={leftOffset}
        ref={carouselRef}
        rightOffset={rightOffset}
        onScroll={handleScroll}
      >
        {children}
      </StyledTabScrollerWrapper>

      {scrollPos > 0 && (
        <ControlButton
          data-dir="prev"
          icon={IconArrowLeft}
          offset={btnLeftOffset}
          view="outline-m"
          onClick={() => scrollLeftTo(-1)}
        />
      )}

      {scrollPos < scrollWidth && (
        <ControlButton
          data-dir="next"
          icon={IconArrowRight}
          offset={btnRightOffset}
          view="outline-m"
          onClick={() => scrollLeftTo(1)}
        />
      )}
    </StyledTabScrollerContainer>
  )
}
