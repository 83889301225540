import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { TThemeTokens } from 'shared/lib/theme'

import * as I from './components'
import * as CyberI from './cybersport-icon'
import { EAdditionalSportsCodes } from './sport-icon.types'

export const SPORTS_ICON_MAP = {
  0: I.IconSportTemplate,
  [ESportsCodes.FOOTBALL]: I.IconSportFootball,
  [ESportsCodes.TENNIS]: I.IconSportTennis,
  [ESportsCodes.BASKETBALL]: I.IconSportBasketball,
  [ESportsCodes.ICE_HOCKEY]: I.IconSportHockey,
  [ESportsCodes.VOLLEYBALL]: I.IconSportVolleyball,
  [ESportsCodes.TABLE_TENNIS]: I.IconSportTableTennis,
  [ESportsCodes.CYBER_SPORT]: I.IconSportCybersport,
  [ESportsCodes.MMA]: I.IconSportMMA,
  [EAdditionalSportsCodes.POPMMA]: I.IconSportPOPMMA,
  [ESportsCodes.BOX]: I.IconSportBoxing,
  [ESportsCodes.AUSTRALIAN_FOOTBALL]: I.IconSportAustralianFootball,
  [ESportsCodes.AMERICAN_FOOTBALL]: I.IconSportAmericanFootball,
  [ESportsCodes.BADMINTON]: I.IconSportBadminton,
  [ESportsCodes.BASKETBALL_3X3]: I.IconSportBasketball3vs3,
  [ESportsCodes.BASEBALL]: I.IconSportBaseball,
  [ESportsCodes.BIATHLON]: I.IconSportBiathlon,
  [ESportsCodes.BILLIARDS]: I.IconSportBilliards,
  [EAdditionalSportsCodes.SNOOKER]: I.IconSportBilliards,
  [EAdditionalSportsCodes.BINGO]: I.IconSportBingo,
  [ESportsCodes.BOBSLED]: I.IconSportBobsled,
  [ESportsCodes.BOWLING]: I.IconSportBowling,
  [ESportsCodes.CYCLING]: I.IconSportCycling,
  [EAdditionalSportsCodes.WINDSURF]: I.IconSportWindsurf,
  [ESportsCodes.WATER_POLO]: I.IconSportWaterPolo,
  [ESportsCodes.FREESTYLE_WRESTLING]: I.IconSportWrestlingFreestyle,
  [ESportsCodes.HANDBALL]: I.IconSportHandball,
  [ESportsCodes.GOLF]: I.IconSportGolf,
  [ESportsCodes.ALPINE_SKIING]: I.IconSportSkiing,
  [ESportsCodes.SKIING]: I.IconSportSkiing,
  [ESportsCodes.ROWING_ACADEMIC]: I.IconSportRowing,
  [ESportsCodes.ROWING_ON_KAYAKS_AND_CANOES]: I.IconSportCanoeing,
  [ESportsCodes.GRECOROMAN_WRESTLING]: I.IconSportWrestlingGrecoRoman,
  [ESportsCodes.GAELIC_FOOTBALL]: I.IconSportGaelicFootball,
  [ESportsCodes.DARTS]: I.IconSportDarts,
  [ESportsCodes.DUATHLON]: I.IconSportDuathlon,
  [EAdditionalSportsCodes.ANIMALS]: I.IconSportAnimals,
  [ESportsCodes.KABBADI]: I.IconSportKabaddi,
  [ESportsCodes.CURLING]: I.IconSportCurling,
  [ESportsCodes.HORSE_RACING]: I.IconSportHorseRacing,
  [ESportsCodes.HORSEBACK_RIDING]: I.IconSportHorseRacing,
  [ESportsCodes.SKATING]: I.IconSportSpeedSkating,
  [ESportsCodes.CRICKET]: I.IconSportCricket,
  [EAdditionalSportsCodes.CULTURE]: I.IconSportCulture,
  [ESportsCodes.LACROSSE]: I.IconSportLacrosse,
  [ESportsCodes.ATHLETICS]: I.IconSportAthletics,
  [EAdditionalSportsCodes.RUNNING]: I.IconSportAthletics,
  [ESportsCodes.NORDIC_COMBINED]: I.IconSportNordicCombined,
  [ESportsCodes.MINI_FOOTBALL]: I.IconSportMiniFootball,
  [ESportsCodes.FUTSAL]: I.IconSportMiniFootball,
  [ESportsCodes.MOTORCYCLE_RACING]: I.IconSportMotosport,
  [ESportsCodes.NETBALL]: I.IconSportNetball,
  [EAdditionalSportsCodes.OLYMPICS]: I.IconSportOlympics,
  [ESportsCodes.SAILING]: I.IconSportSailing,
  [ESportsCodes.PESAPALLO]: I.IconSportPesapallo,
  [ESportsCodes.SWIMMING]: I.IconSportSwimming,
  [ESportsCodes.BEACH_VOLLEYBALL]: I.IconSportBeachVolleyball,
  [ESportsCodes.BEACH_HANDBALL]: I.IconSportBeachHandball,
  [ESportsCodes.BEACH_SOCCER]: I.IconSportBeachSoccer,
  [EAdditionalSportsCodes.POLITICS]: I.IconSportPolitics,
  [ESportsCodes.DIVING]: I.IconSportDiving,
  [ESportsCodes.SKI_JUMPING]: I.IconSportSkiJumping,
  [ESportsCodes.BULLET_SHOOTING]: I.IconSportShooting,
  [ESportsCodes.RALLY]: I.IconSportRally,
  [ESportsCodes.DTM]: I.IconSportRally,
  [ESportsCodes.NASCAR]: I.IconSportRally,
  [ESportsCodes.RUGBY]: I.IconSportRugby,
  [ESportsCodes.RUGBY_7]: I.IconSportRugby,
  [ESportsCodes.RUGBY_UNION]: I.IconSportRugby,
  [ESportsCodes.RUGBY_LEAGUE]: I.IconSportRugby,
  [ESportsCodes.FISHING]: I.IconSportFishing,
  [ESportsCodes.SKELETON]: I.IconSportSkeleton,
  [ESportsCodes.SLED]: I.IconSportSkeleton,
  [ESportsCodes.SEPAK_TAKRAW]: I.IconSportSepaktakraw,
  [ESportsCodes.SYNCHRONIZED_SWIMMING]: I.IconSportSynchronizedSwimming,
  [ESportsCodes.SQUASH]: I.IconSportSquash,
  [ESportsCodes.SKATEBOARDING]: I.IconSportSkateboarding,
  [ESportsCodes.SNOWBOARDING]: I.IconSportSnowboarding,
  [ESportsCodes.MODERN_PENTATHLON]: I.IconSportModernPentathlon,
  [ESportsCodes.SOFTBALL]: I.IconSportSoftball,
  [ESportsCodes.SPEEDWAY]: I.IconSportSpeedway,
  [ESportsCodes.GYMNASTICS]: I.IconSportArtisticGymnastics,
  [ESportsCodes.ARCHERY]: I.IconSportArchery,
  [ESportsCodes.PISTOL_SHOOTING]: I.IconSportPistolShooting,
  [ESportsCodes.TRIATHLON]: I.IconSportTriathlon,
  [ESportsCodes.WEIGHTLIFTING]: I.IconSportWeightlifting,
  [ESportsCodes.FIGURE_SKATING]: I.IconSportFigureSkating,
  [ESportsCodes.FLOORBALL]: I.IconSportFloorball,
  [ESportsCodes.FORMULA_1]: I.IconSportFormula1,
  [ESportsCodes.FREESTYLE]: I.IconSportFreestyle,
  [ESportsCodes.FIELD_HOCKEY]: I.IconSportFieldHockey,
  [ESportsCodes.HOCKEY_WITH_BALL]: I.IconSportBandy,
  [ESportsCodes.BALLS]: I.IconSportBowls,
  [ESportsCodes.CHESS]: I.IconSportChess,
  [ESportsCodes.SHORT_TRACK]: I.IconSportShortTrack,
  [ESportsCodes.SHORT_HOCKEY]: I.IconSportHockeyShort,
  [ESportsCodes.TEQBOL]: I.IconSportTeqball,
  [ESportsCodes.CYBER_BASKETBALL]: I.IconSportCyberbasketball,
  [ESportsCodes.CYBER_TENNIS]: I.IconSportCybertennis,
  [ESportsCodes.CYBER_FOOTBALL]: I.IconSportCyberfootball,
  [ESportsCodes.CYBER_HOCKEY]: I.IconSportCyberhockey,
  [EAdditionalSportsCodes.ALL_SPORTS]: I.IconSportAllSports,
  [EAdditionalSportsCodes.HURLING]: I.IconSportHurling,
  [EAdditionalSportsCodes.FIST_FIGHT]: I.IconSportFistFight,
  [ESportsCodes.CS2]: CyberI.IconCybersportCounterStrike2,
  [ESportsCodes.CS_GO]: CyberI.IconCybersportCSGO,
  [ESportsCodes.DOTA_2]: CyberI.IconCybersportDota2,
  [ESportsCodes.LEAGUE_OF_LEGENDS]: CyberI.IconCybersportLeagueOfLegends,
  [ESportsCodes.KING_OF_GLORY]: CyberI.IconCybersportKingOfGlory,
  [ESportsCodes.VALORANT]: CyberI.IconCybersportValorant,
  [ESportsCodes.RAINBOW_SIX]: CyberI.IconCybersportRainbowSix,
  [ESportsCodes.LEAGUE_OF_LEGENDS_WILD_RIFT]:
    CyberI.IconCybersportLeagueOfLegendsWildRift,
  [ESportsCodes.MOBILE_LEGENDS]: CyberI.IconCybersportMobileLegends,
  [ESportsCodes.PUBG]: CyberI.IconCybersportPUBG,
  [ESportsCodes.PUBG_MOBILE]: CyberI.IconCybersportPUBGMobile,
  [ESportsCodes.CALL_OF_DUTY]: CyberI.IconCybersportCallOfDuty,
  [ESportsCodes.STARCRAFT_2]: CyberI.IconCybersportStarCraft2,
  [ESportsCodes.OVERWATCH_2]: CyberI.IconCybersportOverwatch2,
  [ESportsCodes.AGE_OF_EMPIRES]: CyberI.IconCybersportAgeOfEmpires,
  [ESportsCodes.APEX_LEGENDS]: CyberI.IconCybersportApexLegends,
  [ESportsCodes.ARENA_OF_VALOR]: CyberI.IconCybersportArenaOfValor,
  [ESportsCodes.ARTIFACT]: CyberI.IconCybersportArtifact,
  [ESportsCodes.BRAWL_STARS]: CyberI.IconCybersportBrawlStars,
  [ESportsCodes.CLASH_ROYALE]: CyberI.IconCybersportClashRoyale,
  [ESportsCodes.CROSSFIRE]: CyberI.IconCybersportCrossFire,
  [ESportsCodes.FORTNITE]: CyberI.IconCybersportFortnite,
  [ESportsCodes.FREE_FIRE]: CyberI.IconCybersportFreeFire,
  [ESportsCodes.GEARS_OF_WAR]: CyberI.IconCybersportGearsOfWar,
  [ESportsCodes.GWENT]: CyberI.IconCybersportGwent,
  [ESportsCodes.HALO]: CyberI.IconCybersportHalo,
  [ESportsCodes.HEARTHSTONE]: CyberI.IconCybersportHearthstone,
  [ESportsCodes.HEROES_OF_NEWERTH]: CyberI.IconCybersportHeroesOfNewerth,
  [ESportsCodes.HEROES_OF_THE_STORM]: CyberI.IconCybersportHeroesOfTheStorm,
  [ESportsCodes.OVERWATCH]: CyberI.IconCybersportOverwatch,
  [ESportsCodes.QUAKE]: CyberI.IconCybersportQuake,
  [ESportsCodes.ROCKET_LEAGUE]: CyberI.IconCybersportRocketLeague,
  [ESportsCodes.STARCRAFT]: CyberI.IconCybersportStarCraft,
  [ESportsCodes.STREET_FIGHTER]: CyberI.IconCybersportStreetFighter,
  [ESportsCodes.TEKKEN_7]: CyberI.IconCybersportTekken7,
  [ESportsCodes.VAINGLORY]: CyberI.IconCybersportVainGlory,
  [ESportsCodes.WARCRAFT_III]: CyberI.IconCybersportWarcraftIII,
  [ESportsCodes.WORLD_OF_TANKS]: CyberI.IconCybersportWorldOfTanks,
  [ESportsCodes.WORLD_OF_WARCRAFT]: CyberI.IconCybersportWorldOfWarcraft,
  [ESportsCodes.MORTAL_KOMBAT_XL]: CyberI.IconCybersportMortalKombatXL,
  [ESportsCodes.STREET_FIGHTER_V]: CyberI.IconCybersportStreetFighterV,
  [EAdditionalSportsCodes.SUMO]: I.IconSportSumo
}

export const SPORT_MAIN_COLOR_MAP: Record<
  keyof typeof SPORTS_ICON_MAP,
  NestedKeyOf<TThemeTokens>
> = {
  0: 'sport-icon.sport-global',
  [ESportsCodes.FOOTBALL]: 'sport-icon.sport-football',
  [ESportsCodes.TENNIS]: 'sport-icon.sport-tennis',
  [ESportsCodes.BASKETBALL]: 'sport-icon.sport-basketball',
  [ESportsCodes.ICE_HOCKEY]: 'sport-icon.sport-hockey',
  [ESportsCodes.VOLLEYBALL]: 'sport-icon.sport-volleyball',
  [ESportsCodes.TABLE_TENNIS]: 'sport-icon.sport-global',
  [ESportsCodes.CYBER_SPORT]: 'sport-icon.sport-cybersport',
  [EAdditionalSportsCodes.POPMMA]: 'sport-icon.sport-popMma',
  [ESportsCodes.MMA]: 'sport-icon.sport-popMma',
  [ESportsCodes.BOX]: 'sport-icon.sport-boxing',
  [ESportsCodes.AUSTRALIAN_FOOTBALL]: 'sport-icon.sport-australianFootball',
  [ESportsCodes.AMERICAN_FOOTBALL]: 'sport-icon.sport-americanFootball',
  [ESportsCodes.BADMINTON]: 'sport-icon.sport-badminton',
  [ESportsCodes.BASKETBALL_3X3]: 'sport-icon.sport-basketball',
  [ESportsCodes.BASEBALL]: 'sport-icon.sport-baseball',
  [ESportsCodes.BIATHLON]: 'sport-icon.sport-biathlon',
  [ESportsCodes.BILLIARDS]: 'sport-icon.sport-billiards',
  [EAdditionalSportsCodes.SNOOKER]: 'sport-icon.sport-billiards',
  [EAdditionalSportsCodes.BINGO]: 'sport-icon.sport-bingo',
  [ESportsCodes.BOBSLED]: 'sport-icon.sport-bobsled',
  [ESportsCodes.BOWLING]: 'sport-icon.sport-bowling',
  [ESportsCodes.CYCLING]: 'sport-icon.sport-cycling',
  [EAdditionalSportsCodes.WINDSURF]: 'sport-icon.sport-windsurfing',
  [ESportsCodes.WATER_POLO]: 'sport-icon.sport-waterPolo',
  [ESportsCodes.FREESTYLE_WRESTLING]: 'sport-icon.sport-freestyleWrestling',
  [ESportsCodes.HANDBALL]: 'sport-icon.sport-handball',
  [ESportsCodes.GOLF]: 'sport-icon.sport-golf',
  [ESportsCodes.ALPINE_SKIING]: 'sport-icon.sport-skiing',
  [ESportsCodes.SKIING]: 'sport-icon.sport-skiing',
  [ESportsCodes.ROWING_ACADEMIC]: 'sport-icon.sport-rowing',
  [ESportsCodes.ROWING_ON_KAYAKS_AND_CANOES]: 'sport-icon.sport-canoeing',
  [ESportsCodes.GRECOROMAN_WRESTLING]: 'sport-icon.sport-grecoRomanWrestling',
  [ESportsCodes.GAELIC_FOOTBALL]: 'sport-icon.sport-gaelicFootball',
  [ESportsCodes.DARTS]: 'sport-icon.sport-darts',
  [ESportsCodes.DUATHLON]: 'sport-icon.sport-athletics',
  [EAdditionalSportsCodes.ANIMALS]: 'sport-icon.sport-animals',
  [ESportsCodes.KABBADI]: 'sport-icon.sport-kabaddi',
  [ESportsCodes.CURLING]: 'sport-icon.sport-curling',
  [ESportsCodes.HORSE_RACING]: 'sport-icon.sport-horseRacing',
  [ESportsCodes.HORSEBACK_RIDING]: 'sport-icon.sport-horseRacing',
  [ESportsCodes.SKATING]: 'sport-icon.sport-speedSkating',
  [ESportsCodes.CRICKET]: 'sport-icon.sport-cricket',
  [EAdditionalSportsCodes.CULTURE]: 'sport-icon.sport-culture',
  [ESportsCodes.LACROSSE]: 'sport-icon.sport-lacrosse',
  [ESportsCodes.ATHLETICS]: 'sport-icon.sport-athletics',
  [EAdditionalSportsCodes.RUNNING]: 'sport-icon.sport-athletics',
  [ESportsCodes.NORDIC_COMBINED]: 'sport-icon.sport-nordicCombined',
  [ESportsCodes.MINI_FOOTBALL]: 'sport-icon.sport-minifootball',
  [ESportsCodes.FUTSAL]: 'sport-icon.sport-minifootball',
  [ESportsCodes.MOTORCYCLE_RACING]: 'sport-icon.sport-motocycleRacing',
  [ESportsCodes.NETBALL]: 'sport-icon.sport-netball',
  [EAdditionalSportsCodes.OLYMPICS]: 'sport-icon.sport-global',
  [ESportsCodes.SAILING]: 'sport-icon.sport-sailing',
  [ESportsCodes.PESAPALLO]: 'sport-icon.sport-pesapallo',
  [ESportsCodes.SWIMMING]: 'sport-icon.sport-swimming',
  [ESportsCodes.BEACH_VOLLEYBALL]: 'sport-icon.sport-volleyball',
  [ESportsCodes.BEACH_HANDBALL]: 'sport-icon.sport-handball',
  [ESportsCodes.BEACH_SOCCER]: 'sport-icon.sport-football',
  [EAdditionalSportsCodes.POLITICS]: 'sport-icon.sport-global',
  [ESportsCodes.DIVING]: 'sport-icon.sport-diving',
  [ESportsCodes.SKI_JUMPING]: 'sport-icon.sport-skiJumping',
  [ESportsCodes.BULLET_SHOOTING]: 'sport-icon.sport-global',
  [ESportsCodes.RALLY]: 'sport-icon.sport-rallying',
  [ESportsCodes.DTM]: 'sport-icon.sport-rallying',
  [ESportsCodes.NASCAR]: 'sport-icon.sport-rallying',
  [ESportsCodes.RUGBY]: 'sport-icon.sport-rugby',
  [ESportsCodes.RUGBY_7]: 'sport-icon.sport-rugby',
  [ESportsCodes.RUGBY_UNION]: 'sport-icon.sport-rugby',
  [ESportsCodes.RUGBY_LEAGUE]: 'sport-icon.sport-rugby',
  [ESportsCodes.FISHING]: 'sport-icon.sport-fishing',
  [ESportsCodes.SKELETON]: 'sport-icon.sport-skeleton',
  [ESportsCodes.SLED]: 'sport-icon.sport-skeleton',
  [ESportsCodes.SEPAK_TAKRAW]: 'sport-icon.sport-sepaktakraw',
  [ESportsCodes.SYNCHRONIZED_SWIMMING]: 'sport-icon.sport-synchronizedSwimming',
  [ESportsCodes.SQUASH]: 'sport-icon.sport-squash',
  [ESportsCodes.SKATEBOARDING]: 'sport-icon.sport-skateboarding',
  [ESportsCodes.SNOWBOARDING]: 'sport-icon.sport-snowboarding',
  [ESportsCodes.MODERN_PENTATHLON]: 'sport-icon.sport-fencing',
  [ESportsCodes.SOFTBALL]: 'sport-icon.sport-softball',
  [ESportsCodes.SPEEDWAY]: 'sport-icon.sport-speedway',
  [ESportsCodes.GYMNASTICS]: 'sport-icon.sport-artisticGymnastics',
  [ESportsCodes.ARCHERY]: 'sport-icon.sport-archery',
  [ESportsCodes.PISTOL_SHOOTING]: 'sport-icon.sport-pistolShooting',
  [ESportsCodes.TRIATHLON]: 'sport-icon.sport-swimming',
  [ESportsCodes.WEIGHTLIFTING]: 'sport-icon.sport-weightlifting',
  [ESportsCodes.FIGURE_SKATING]: 'sport-icon.sport-figureskating',
  [ESportsCodes.FLOORBALL]: 'sport-icon.sport-floorball',
  [ESportsCodes.FORMULA_1]: 'sport-icon.sport-formula1',
  [ESportsCodes.FREESTYLE]: 'sport-icon.sport-freestyle',
  [ESportsCodes.FIELD_HOCKEY]: 'sport-icon.sport-fieldHockey',
  [ESportsCodes.HOCKEY_WITH_BALL]: 'sport-icon.sport-bandy',
  [ESportsCodes.BALLS]: 'sport-icon.sport-global',
  [ESportsCodes.CHESS]: 'sport-icon.sport-chess',
  [ESportsCodes.SHORT_TRACK]: 'sport-icon.sport-shortTrack',
  [ESportsCodes.SHORT_HOCKEY]: 'sport-icon.sport-hockey',
  [ESportsCodes.TEQBOL]: 'sport-icon.sport-teqball',
  [ESportsCodes.CYBER_BASKETBALL]: 'sport-icon.sport-basketball',
  [ESportsCodes.CYBER_TENNIS]: 'sport-icon.sport-tennis',
  [ESportsCodes.CYBER_FOOTBALL]: 'sport-icon.sport-football',
  [ESportsCodes.CYBER_HOCKEY]: 'sport-icon.sport-hockey',
  [EAdditionalSportsCodes.ALL_SPORTS]: 'sport-icon.sport-allSports',
  [EAdditionalSportsCodes.HURLING]: 'sport-icon.sport-hurling',
  [EAdditionalSportsCodes.FIST_FIGHT]: 'sport-icon.sport-popMma',
  [ESportsCodes.CS2]: 'sport-icon.sport-global',
  [ESportsCodes.CS_GO]: 'sport-icon.sport-global',
  [ESportsCodes.DOTA_2]: 'sport-icon.sport-global',
  [ESportsCodes.LEAGUE_OF_LEGENDS]: 'sport-icon.sport-global',
  [ESportsCodes.KING_OF_GLORY]: 'sport-icon.sport-global',
  [ESportsCodes.VALORANT]: 'sport-icon.sport-global',
  [ESportsCodes.RAINBOW_SIX]: 'sport-icon.sport-global',
  [ESportsCodes.LEAGUE_OF_LEGENDS_WILD_RIFT]: 'sport-icon.sport-global',
  [ESportsCodes.MOBILE_LEGENDS]: 'sport-icon.sport-global',
  [ESportsCodes.PUBG]: 'sport-icon.sport-global',
  [ESportsCodes.PUBG_MOBILE]: 'sport-icon.sport-global',
  [ESportsCodes.CALL_OF_DUTY]: 'sport-icon.sport-global',
  [ESportsCodes.STARCRAFT_2]: 'sport-icon.sport-global',
  [ESportsCodes.OVERWATCH_2]: 'sport-icon.sport-global',
  [ESportsCodes.AGE_OF_EMPIRES]: 'sport-icon.sport-global',
  [ESportsCodes.APEX_LEGENDS]: 'sport-icon.sport-global',
  [ESportsCodes.ARENA_OF_VALOR]: 'sport-icon.sport-global',
  [ESportsCodes.ARTIFACT]: 'sport-icon.sport-global',
  [ESportsCodes.BRAWL_STARS]: 'sport-icon.sport-global',
  [ESportsCodes.CLASH_ROYALE]: 'sport-icon.sport-global',
  [ESportsCodes.CROSSFIRE]: 'sport-icon.sport-global',
  [ESportsCodes.FORTNITE]: 'sport-icon.sport-global',
  [ESportsCodes.FREE_FIRE]: 'sport-icon.sport-global',
  [ESportsCodes.GEARS_OF_WAR]: 'sport-icon.sport-global',
  [ESportsCodes.GWENT]: 'sport-icon.sport-global',
  [ESportsCodes.HALO]: 'sport-icon.sport-global',
  [ESportsCodes.HEARTHSTONE]: 'sport-icon.sport-global',
  [ESportsCodes.HEROES_OF_NEWERTH]: 'sport-icon.sport-global',
  [ESportsCodes.HEROES_OF_THE_STORM]: 'sport-icon.sport-global',
  [ESportsCodes.OVERWATCH]: 'sport-icon.sport-global',
  [ESportsCodes.QUAKE]: 'sport-icon.sport-global',
  [ESportsCodes.ROCKET_LEAGUE]: 'sport-icon.sport-global',
  [ESportsCodes.STARCRAFT]: 'sport-icon.sport-global',
  [ESportsCodes.STREET_FIGHTER]: 'sport-icon.sport-global',
  [ESportsCodes.TEKKEN_7]: 'sport-icon.sport-global',
  [ESportsCodes.VAINGLORY]: 'sport-icon.sport-global',
  [ESportsCodes.WARCRAFT_III]: 'sport-icon.sport-global',
  [ESportsCodes.WORLD_OF_TANKS]: 'sport-icon.sport-global',
  [ESportsCodes.WORLD_OF_WARCRAFT]: 'sport-icon.sport-global',
  [ESportsCodes.MORTAL_KOMBAT_XL]: 'sport-icon.sport-global',
  [ESportsCodes.STREET_FIGHTER_V]: 'sport-icon.sport-global',
  [EAdditionalSportsCodes.SUMO]: 'sport-icon.sport-popMma'
}
