import { bonusOnExpressToPercent, formatAmountLocaleString } from 'astra-core'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { BetStatus, BetType } from 'betweb-openapi-axios'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  getBetBonusAmountFormatted,
  getExpressBonusSum
} from 'entities/bet-history'
import { useBetsHistoryContext } from 'entities/bet-history/lib/bet-history.context'
import {
  BetsHistoryHeader,
  BetsHistoryOutcomesTable
} from 'features/bets-history-outcomes-table'
import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { computed } from 'shared/lib/computed'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { IconInfoCircleSFilled } from 'shared/ui/Icon/General/IconInfoCircleSFilled'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'
import { CellEventCashout } from 'widgets/bets-history/components/Cashout'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { BetHistoryCardIcon } from './bet-history-card-icon'
import {
  CardDataAmountValue,
  CardDataBlockAmount,
  CardDataBlockBonus,
  CardDataBlockCashout,
  CardDataBlockName,
  CardDataBlockOdd,
  CardDataBlockTag,
  CardDataCoupon,
  CardDataFixedContainer,
  CardDataLabel,
  CardDataValue,
  CardDate,
  CardMain,
  CardName,
  CardTable,
  CardWrapper,
  CollapseCardIcon,
  CollapsedWrapper,
  CouponAmount,
  CouponTitle,
  ExpressBonusContent,
  ExpressBonusLabel,
  ExpressBonusPercent,
  ExpressBonusPercentContent,
  ExpressBonusSum,
  MainInfoBlock,
  StyledBasketHistoryTextBonus,
  StyledBonusIconWrapper,
  StyledEmptySpace,
  WonAmount
} from './bet-history-card.styled'
import { BetHistoryCardProps, ECellEventTitle } from './bet-history-card.types'
import { BetHistoryTag } from './bet-history-tag'
import { getExpressSystemOutcomesCount } from './bet-history-utils'

export const BetHistoryCard: FC<BetHistoryCardProps> = ({ item, number }) => {
  const { t } = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const config = useAppSelector(selectConfig)

  const { allCollapsed } = useBetsHistoryContext()

  const [isCollapsed, setIsExpressSystemCollapsed] = useState(allCollapsed)

  const onClickRowCollapse = useCallback(() => {
    if (item.betType !== BetType.Ordinar) {
      setIsExpressSystemCollapsed((prev) => !prev)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (item.betType !== BetType.Ordinar) {
      setIsExpressSystemCollapsed(allCollapsed)
    }
  }, [allCollapsed]) // eslint-disable-line react-hooks/exhaustive-deps

  const bonusAsPercent = bonusOnExpressToPercent(item.coefMultiplier ?? 1)

  const betAmountFormatted = getBetBonusAmountFormatted({
    bet: item,
    currencyIcon
  })

  const { outcomes } = item
  const betCount = computed(() => {
    const outcomesCount = getExpressSystemOutcomesCount(item)

    if (outcomes.length < 2) return ''
    if (item.betType === BetType.System) {
      return outcomesCount.toLowerCase()
    }
    return `${outcomesCount} ${t('events', {
      count: outcomes.length
    }).toLowerCase()}`
  })
  const betName = ECellEventTitle[item.betType]
    ? `${t(ECellEventTitle[item.betType])} ${betCount}`
    : ECellEventTitle.default
  const betTitle = number ? `#${number} ${betName}` : betName

  const hideBetAmount =
    config.IS_TRANSIT ||
    !betAmountFormatted ||
    !Number.parseInt(betAmountFormatted, 10) ||
    item.status === BetStatus.Lost ||
    item.status === BetStatus.Returned

  const couponAmount = formatAmountLocaleString({
    value: item.bet,
    currency: currencyIcon
  })

  return (
    <CollapsedWrapper>
      <CardWrapper>
        <CardMain onClick={onClickRowCollapse}>
          <MainInfoBlock>
            <BetHistoryCardIcon betStatus={item.status} />
            <CardDataBlockName>
              <CardName>{betTitle}</CardName>
              <CardDate>
                {dayjs(item.timestamp).format('DD.MM.YY HH:mm')}
              </CardDate>
            </CardDataBlockName>
          </MainInfoBlock>

          <CardDataBlockCashout>
            <CellEventCashout id={item.id} number={number} />
          </CardDataBlockCashout>

          <CardDataBlockAmount justifyContent="flex-end">
            <CardDataLabel>{t('bet amount')}</CardDataLabel>

            {item.appliedCouponId ? (
              <CardDataCoupon>
                <CouponTitle>
                  <IconTicketNo />
                  {t('coupon applied')}
                </CouponTitle>
                <CouponAmount>{couponAmount}</CouponAmount>
              </CardDataCoupon>
            ) : (
              <CardDataAmountValue>
                <StyledBasketHistoryTextBonus>
                  {formatAmountLocaleString({
                    value: item.bet,
                    currency: item.bonusWallet ? '' : currencyIcon
                  })}
                  {item.bonusWallet ? <IconBonus size={12} /> : null}
                </StyledBasketHistoryTextBonus>
              </CardDataAmountValue>
            )}
          </CardDataBlockAmount>

          <CardDataBlockOdd justifyContent="flex-end">
            {![BetType.Ordinar, BetType.System].includes(item.betType) ? (
              <>
                <CardDataLabel>{t('total odds')}</CardDataLabel>
                <CardDataValue>
                  {formatAmountLocaleString({
                    value: item.coefficient?.toFixed(2)
                  })}
                </CardDataValue>
              </>
            ) : null}
          </CardDataBlockOdd>

          <CardDataFixedContainer>
            {item.betType !== BetType.System ||
            item.status !== BetStatus.Current ? (
              <>
                {item.betType === BetType.Express &&
                item.status !== BetStatus.Sold &&
                getExpressBonusSum(item) > 0 ? (
                  <CardDataBlockBonus>
                    <CardDataLabel>
                      <ExpressBonusLabel>
                        <ExpressBonusModal>
                          <StyledBonusIconWrapper>
                            <IconInfoCircleSFilled />
                          </StyledBonusIconWrapper>
                        </ExpressBonusModal>
                        {t('bonus')}
                      </ExpressBonusLabel>
                    </CardDataLabel>

                    <ExpressBonusContent>
                      <ExpressBonusSum>
                        +{formatMonetaryAmount(getExpressBonusSum(item))}{' '}
                        <div>{currencyIcon}</div>
                      </ExpressBonusSum>
                      <ExpressBonusPercent>
                        <IconPolygonBgLeftPart />
                        <ExpressBonusPercentContent>
                          {bonusAsPercent}%
                        </ExpressBonusPercentContent>
                        <IconPolygonBgLeftPart twist={180} />
                      </ExpressBonusPercent>
                    </ExpressBonusContent>
                  </CardDataBlockBonus>
                ) : null}
              </>
            ) : null}

            <CardDataBlockTag justifyContent="flex-end">
              <BetHistoryTag status={item.status} />
              {!hideBetAmount && <WonAmount>{betAmountFormatted}</WonAmount>}
            </CardDataBlockTag>

            {item.betType !== BetType.Ordinar ? (
              <CollapseCardIcon size={16} twist={isCollapsed ? 180 : 0} />
            ) : (
              <StyledEmptySpace />
            )}
          </CardDataFixedContainer>
        </CardMain>

        {(!isCollapsed || item.betType === BetType.Ordinar) && (
          <CardTable>
            <BetsHistoryHeader />

            <BetsHistoryOutcomesTable outcomes={item.outcomes} />
          </CardTable>
        )}
      </CardWrapper>
    </CollapsedWrapper>
  )
}
