import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { useAppSelector } from 'shared/lib/@reduxjs'

import { BalanceOperationsTypes } from '../../model'

export const useGetIframeUrl = (
  paymentSystem: string,
  balanceOperationType: BalanceOperationsTypes
) => {
  const { GATEWAY_API_URL } = useAppSelector(selectConfig)

  return paymentSystem
    ? `${GATEWAY_API_URL}/kzapi/pay/${
        balanceOperationType === BalanceOperationsTypes.TOP_UP ? 'in' : 'out'
      }/form?pay-sys=${paymentSystem}&session=__session__&platforma=NEWOLIMPBET`
    : ''
}
