import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconTimer: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4840_28)">
        <path
          clipRule="evenodd"
          d="M4 0.497559C4 0.221405 4.22386 -0.00244141 4.5 -0.00244141H7.5C7.77614 -0.00244141 8 0.221405 8 0.497559C8 0.773712 7.77614 0.997559 7.5 0.997559H6.96634V2H7.38866C7.83069 2 8.24771 2.17514 8.56464 2.49207L8.87479 2.80356L9.39568 2.28268C9.62999 2.04837 10.0099 2.04834 10.2442 2.28268L10.6978 2.73627C10.9321 2.97058 10.9321 3.35049 10.6978 3.58481L10.1741 4.10846L10.5079 4.4437C10.8249 4.75229 11 5.17764 11 5.61969V8.38031C11 8.82236 10.8249 9.23938 10.5079 9.5563L8.5563 11.5079C8.24771 11.8249 7.82236 12 7.38031 12H4.61969C4.17764 12 3.76064 11.8249 3.44371 11.5079L1.49208 9.5563C1.17514 9.24771 1 8.82236 1 8.38031V5.61969C1 5.17764 1.17514 4.76062 1.49208 4.4437L3.45204 2.49207C3.76064 2.17514 4.18599 2 4.62802 2H5.13089V0.997559H4.5C4.22386 0.997559 4 0.773712 4 0.497559ZM6 3.39999H4.62802C4.55444 3.39999 4.49371 3.42908 4.45511 3.46875L4.44756 3.4765L2.48203 5.43365C2.42245 5.4935 2.39999 5.55713 2.39999 5.61969V8.38031C2.39999 8.45389 2.42908 8.51462 2.46873 8.55322L2.47543 8.55975L4.43365 10.518C4.4937 10.578 4.5569 10.6 4.61969 10.6H7.38031C7.45389 10.6 7.51462 10.5709 7.55324 10.5312L7.55975 10.5246L9.51797 8.56635C9.57802 8.50629 9.60001 8.44312 9.60001 8.38031V8H7.88C7.61 8 7.35001 7.89978 7.16 7.70935L6.3 6.85745C6.11 6.66705 6 6.41647 6 6.14587V3.39999Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_4840_28">
          <rect fill="white" height="12" width="12" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
)
