import { FC } from 'react'

import { IconMaxTag } from 'shared/ui/Icon/General/IconMaxTag'
import { Tooltip } from 'shared/ui/Tooltip'

import * as S from './tag-max.styled'
import { ETagMaxColor } from './types'

export const TagMax: FC<{
  tooltipText: string
  iconColor: ETagMaxColor
}> = ({ tooltipText, iconColor = ETagMaxColor.GREEN }) => (
  <Tooltip maxWidth={288} title={tooltipText} topPlacement>
    <S.MaxTagContainer iconColor={iconColor}>
      <IconMaxTag />
    </S.MaxTagContainer>
  </Tooltip>
)
