import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { AppLink } from 'shared/lib/app-link'

export const StyledBasketErrorWrapper = styled.div`
  display: flex;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-warning']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    outline: 1px solid ${theme.tokens.colors.border['border-warning-opacity']};
    outline-offset: -1px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledIconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.icon['icon-warning']};
    display: flex;
  `}
`

export const StyledBasketErrorText = styled.div`
  ${typography['Body / 12 Medium']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
  `}
`

export const StyledLink = styled(AppLink)`
  display: block;
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-linkAccent-default']};

    &:hover {
      color: ${({ theme }) =>
        theme.tokens.colors.text['text-linkAccent-hover']};
    }
  `}
`

export const StyledA = styled.a`
  display: block;
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-linkAccent-default']};

    &:hover {
      color: ${({ theme }) =>
        theme.tokens.colors.text['text-linkAccent-hover']};
    }
  `}
`

export const StyledBasketErrorAction = styled.span`
  cursor: pointer;
  text-decoration: underline;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[80]};
    `}
`
