import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledOutcomesGroupsTab = styled.div<{ isActive?: boolean }>`
  ${typography['Hint / 10 BOLD']}
  ${({ theme, isActive }) => css`
    align-items: center;
    background-color: ${theme.tokens.colors.surface['surface-default']};
    border-radius: 6px;
    color: ${theme.tokens.colors.text['text-secondary-3']};
    cursor: pointer;
    display: flex;
    gap: 4px;
    padding: 4px 8px;
    transition: background-color 0.25s ease;
    width: 100%;

    &:hover {
      ${!isActive &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-hover']};
        color: ${theme.tokens.colors.text['text-secondary']};
      `}
    }

    ${isActive &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-menu-active']};
      color: ${theme.tokens.colors.text['text-primary']};
    `}
  `}
`
