import { EventsGroup } from 'astra-core/hooks/useEvents/types'
import { Event } from 'betweb-openapi-axios'

import { EventRowProps, EventTableHeaderProps } from 'features/events-table'

export interface EventsTableProps {
  events: Event[]
  headerProps?: Omit<
    Omit<EventTableHeaderProps, 'tournamentName'>,
    'tournamentSportId'
  >
  rowProps?: Pick<EventRowProps, 'withSearchHighlight'>
  topHeaderCodesOnly?: boolean
  isBorderRadius?: boolean
  sortByTags?: boolean
  isDayEvent?: boolean
}

export interface EventTournamentsTableProps {
  group: EventsGroup[]
  headerProps?: Omit<
    Omit<EventTableHeaderProps, 'tournamentName'>,
    'tournamentSportId'
  >
  topHeaderCodesOnly?: boolean
}

export enum ESportsMenuTypes {
  Line = 'line',
  Live = 'live',
  CALENDAR = 'calendar'
}
