import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { customMedia } from 'shared/lib/styled'

import { IStyledBetContentItem } from './outcomes-table-legacy.types'

export const StyledBetContentItem = styled.tr<IStyledBetContentItem>``

export const Table = styled.tbody`
  border-collapse: collapse;
  max-width: 100%;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-disabled']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
  `}
`

export const TableHeadCell = styled.th<{
  colWidth?: string
  largeColWidth?: string
  maxWidth?: string
  textAlign?: 'start' | 'end'
}>`
  ${typography['Body / 12 Medium']};
  text-align: left;
  white-space: nowrap;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-disabled']};
    color: ${theme.tokens.colors.text['text-description']};
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px 0;
  `};
  ${({ colWidth }) =>
    colWidth &&
    css`
      width: ${colWidth};
    `};
  ${({ largeColWidth }) =>
    largeColWidth &&
    css`
      ${customMedia.medium} {
        width: ${largeColWidth};
      }

      ${customMedia.large} {
        width: ${largeColWidth};
      }
    `};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
`

export const StyledCellOutcome = styled.td<{
  fitContent?: boolean
  textAlign?: 'start' | 'end'
}>`
  ${typography['Body / 12 SemiBold']};
  vertical-align: top;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
  ${({ fitContent }) => css`
    ${fitContent &&
    css`
      white-space: nowrap;
      width: 1%;
    `}
  `};
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
`

export const EventNameWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const OverflowableText = styled.span`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;

  ${customMedia.small} {
    -webkit-line-clamp: 2;
  }
`

export const HeaderTr = styled.tr`
  ${({ theme }) => css`
    > :first-child {
      border-top-left-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-s-6'
      ]}px;
    }
    > :last-child {
      border-top-right-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-s-6'
      ]}px;
    }
  `}
`

export const TableTBody = styled.tbody`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-disabled']};
    > tr:last-child {
      > td:first-child {
        border-bottom-left-radius: ${theme.tokens.alias['border-radius'][
          'border-radius-s-6'
        ]}px;
      }
      > td:last-child {
        border-bottom-right-radius: ${theme.tokens.alias['border-radius'][
          'border-radius-s-6'
        ]}px;
      }
    }
  `}
`
