import { FC, memo, useState } from 'react'
import { LayoutTheme } from 'betweb-openapi-axios'
import isEqual from 'react-fast-compare'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { LiveContent, LineContent } from 'features/events-table'
import {
  ETagsTop,
  entityTagsColorsSchema,
  useGetEntityTagName
} from 'entities/event'
import { EventRow } from 'entities/event-row'
import { selectTheme } from 'shared/lib/theme/select-theme'
import { useAppSelector } from 'shared/lib/@reduxjs'
import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

import { Coefficients } from '../coefficients'
import { CompetitorsInfo } from '../competitors-info'

import { EventRowProps } from './event-table-row.types'
import { EventsTableRowPropsLoader } from './event-table-row.loader'

export const EventTableRow: FC<EventRowProps> = memo(
  ({ event, withSearchHighlight, Outcomes }) => {
    const layoutTheme = useAppSelector(selectTheme)
    const hydrated = useHydrated()
    const sportName = useAppSelector((state) =>
      selectSport(state, event.tournament.sportId || 0)
    ).code

    const entityTagName = useGetEntityTagName({ tags: event.tags })

    const [isDisplayTableOutcomes, setIsDisplayTableOutcomes] =
      useState<boolean>()

    return (
      <EventRow
        AdditionalInfo={
          event.live ? (
            <LiveContent event={event} sportName={sportName as ESportsCodes} />
          ) : (
            <LineContent event={event} />
          )
        }
        backgroundImageUrl={
          entityTagName === ETagsTop.SUPERTOP
            ? '/images/shapes/SUPERTOP_tag_bg.svg'
            : ''
        }
        Coefficients={
          hydrated ? (
            <Coefficients
              event={event}
              onToggleExpand={(isDisplay) =>
                setIsDisplayTableOutcomes(isDisplay)
              }
            />
          ) : (
            <EventsTableRowPropsLoader />
          )
        }
        CompetitorsInfo={
          <CompetitorsInfo
            event={event}
            isTitleTeamWithSearchHighlight={!!withSearchHighlight}
          />
        }
        entityTagColor={
          entityTagName ? entityTagsColorsSchema[entityTagName] : ''
        }
        isDarkMode={layoutTheme === LayoutTheme.Dark}
        isDisplayTableOutcomes={!!isDisplayTableOutcomes}
        Outcomes={Outcomes}
      />
    )
  },
  isEqual
)
