import { FC, memo, useMemo } from 'react'
import { Event } from 'betweb-openapi-axios'
import isEqual from 'react-fast-compare'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { EEventStatisticsCodes } from 'shared/lib/events/types'

import * as S from './score-cell.styled'

const typesOfBilliardSports = [ESportsCodes.BILLIARDS]

export const LiveScoreCell: FC<{
  eventStatistics: Event['statistics']
  sportName: ESportsCodes
}> = memo(({ eventStatistics, sportName }) => {
  const gameScoreStatistics = eventStatistics.find(
    (s) => s.code === EEventStatisticsCodes.GameScore
  )
  const scoreByPeriodsStatistics = eventStatistics.find(
    (s) => s.code === EEventStatisticsCodes.ScoresByPeriods
  )

  const scoreStatistics = eventStatistics.find(
    (s) => s.code === EEventStatisticsCodes.Score
  )

  const isBilliardSports = typesOfBilliardSports.includes(sportName)

  const gameScore = gameScoreStatistics?.value
    .split(':')
    .map((item) => (item === '50' ? 'A' : item))

  const scoreByPeriods = useMemo(
    () =>
      scoreByPeriodsStatistics?.value
        .split(',')
        .map((item) => item.split(':'))
        .map(([home, away], period) => ({
          home,
          away,
          period
        })),
    [scoreByPeriodsStatistics?.value]
  )

  const score = scoreStatistics?.value.split(':')

  return (
    <S.LiveContentScoreCell>
      <S.ScoresStatistics>
        {(scoreByPeriods || gameScore) && (
          <S.EventScoreByPeriods>
            {gameScore && (
              <S.EventScoreByPeriodsColumn>
                <span>({gameScore[0]})</span>
                <span>({gameScore[1]})</span>
              </S.EventScoreByPeriodsColumn>
            )}

            {scoreByPeriods && (
              <>
                {!isBilliardSports &&
                  scoreByPeriods.map((item) => (
                    <S.EventScoreByPeriodsColumn key={item.period}>
                      <span>{item.home}</span>
                      <span>{item.away}</span>
                    </S.EventScoreByPeriodsColumn>
                  ))}

                {isBilliardSports && scoreByPeriods?.length && (
                  <S.EventScoreByPeriodsColumn
                    key={scoreByPeriods.at(-1)?.period}
                  >
                    <span>{scoreByPeriods.at(-1)?.home}</span>
                    <span>{scoreByPeriods.at(-1)?.away}</span>
                  </S.EventScoreByPeriodsColumn>
                )}
              </>
            )}
          </S.EventScoreByPeriods>
        )}

        <S.EventScore>
          {score?.length && <div>{score[0]}</div>}
          {score?.length && <div>{score[1]}</div>}
        </S.EventScore>
      </S.ScoresStatistics>
    </S.LiveContentScoreCell>
  )
}, isEqual)
