import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import {
  selectEventsLayoutIsLoading,
  selectHasLineEventDay,
  selectNonDayEvents
} from 'entities/line-tournaments/model/selectors'
import { lineTournamentsContainerActions } from 'entities/line-tournaments/model/slice'
import { LineParams } from 'entities/line/model/types'
import { ClearSearchResults } from 'features/clear-search-results'
import { EmptyPageFilter } from 'features/empty-page-filter'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'
import { getBackLinkBuilder } from 'shared/lib/events'
import { RootState } from 'shared/types/store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { EventsTable } from 'widgets/events-table'
import { ESportsMenuTabTypes } from 'widgets/sports-menu/sports-menu.types'

export const LineEvents: FC = () => {
  const dispatch = useAppDispatch()
  const { tournamentId } = useParams<LineParams>()
  const searchText = useAppSelector(selectInputSearchLine)
  const events = useAppSelector((state: RootState) =>
    selectNonDayEvents(state, tournamentId)
  )
  const hasEventDay = useAppSelector((state) =>
    selectHasLineEventDay(state, tournamentId)
  )

  const isLayoutEventsLoading = useAppSelector(selectEventsLayoutIsLoading)

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetFilters())
  }, [dispatch])

  const handleBackLink = useCallback(
    (tournamentSportId, genericSportId) =>
      getBackLinkBuilder(
        ESportsMenuTabTypes.LINE,
        tournamentSportId,
        genericSportId
      ),
    []
  )

  return (
    <>
      {isLayoutEventsLoading ? (
        <LoaderSpinner />
      ) : events.length ? (
        <EventsTable
          headerProps={{
            backLinkBuilder: handleBackLink
          }}
          events={events}
        />
      ) : searchText ? (
        <ClearSearchResults />
      ) : !hasEventDay ? (
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      ) : (
        <></>
      )}
    </>
  )
}
