import React, { Fragment } from 'react'

import {
  menuListModel,
  StyledFooterBottomMenuListLink
} from 'entities/footer-of-page'

export const FooterOfPageMenuItems = () => {
  const menuItems = menuListModel.useGetMenuList()

  return (
    <>
      {menuItems.map(({ translatedName, path }) => (
        <StyledFooterBottomMenuListLink key={path} to={path}>
          {translatedName}
        </StyledFooterBottomMenuListLink>
      ))}

      {/* {aboutPageDocumentsFromModel.map((document) => (
        <Fragment key={document.link + document.locale}>
          {document.to ? (
            <StyledFooterBottomMenuListLink to={document.to}>
              {t(document.locale)}
            </StyledFooterBottomMenuListLink>
          ) : (
            <StyledFooterBottomMenuListLinkA
              href={`${config.CDN_URL}/${config.RULES_CDN_FOLDER}/${document.link}`}
              target="_blank"
            >
              {t(document.locale)}
            </StyledFooterBottomMenuListLinkA>
          )}
        </Fragment>
      ))} */}
    </>
  )
}
