import {
  selectAccountUser,
  selectFetchUserRequest
} from 'astra-core/containers/AccountProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { useCompleted } from 'hooks'
import { useAppSelector } from 'shared/lib/@reduxjs'

const LIVE_CHAT_GROUP_VALUE = 24

export const useLiveChatIntegration = () => {
  const userFetch = useAppSelector(selectFetchUserRequest)
  const user = useAppSelector(selectAccountUser)
  const config = useAppSelector(selectConfig)
  const isUserLoaded = useCompleted(!!userFetch.loading)
  const { i18n } = useTranslation()
  const currentLang = i18n.language

  useEffect(() => {
    const isAuthorized = isUserLoaded && user

    // Base configs for LiveChat
    window.__lc = window.__lc || {}
    window.__lc.license = config.LIVECHAT_LICENCE
    window.__lc.group = LIVE_CHAT_GROUP_VALUE

    if (isAuthorized) {
      window.__lc.visitor = {
        name: user.firstName || '.',
        email: user.email || ''
      }

      window.__lc.params = [
        { name: 'Phone', value: user.phone || '' },
        { name: 'UserLogin', value: user.login || '' },
        { name: 'Name', value: user.firstName || '' },
        { name: 'Status', value: 'User' },
        { name: 'URL', value: window.location.href },
        { name: 'Domain', value: window.location.hostname },
        { name: 'Type', value: 'Website' },
        { name: 'Lang', value: currentLang },
        { name: 'Platform', value: 'N/A' }
      ]
    } else {
      window.__lc.visitor = { name: '.', email: '' }

      window.__lc.params = [
        { name: 'Status', value: 'Not Authorized' },
        { name: 'URL', value: window.location.href },
        { name: 'Domain', value: window.location.hostname },
        { name: 'Type', value: 'Website' },
        { name: 'Lang', value: currentLang }
      ]
    }

    // Inserting a LiveChat script
    const lc = document.createElement('script')
    lc.type = 'text/javascript'
    lc.async = true
    lc.src = `${
      document.location.protocol === 'https:' ? 'https://' : 'http://'
    }cdn.livechatinc.com/tracking.js?1`
    const s = document.getElementsByTagName('script')[0]
    s.parentNode?.insertBefore(lc, s)
  }, [user, isUserLoaded, config, currentLang])
}
