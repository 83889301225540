import styled, { css } from 'styled-components'

import { ControlButton } from 'shared/ui/Carousel/Carousel.styled'
import { StyledIconButton } from 'shared/ui/buttons/IconButton/IconButton.styled'

import { TabScrollerContainerProps } from './OutcomesGroupsTabs.types'

export const StyledTabScrollerContainer = styled.div<TabScrollerContainerProps>`
  overflow-x: auto;
  position: relative; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${StyledIconButton} {
    height: 24px;
    width: 24px;
  }

  &:hover {
    ${ControlButton} {
      display: flex;
    }
  }

  ${({ theme, isScrollableToLeft, isScrollableToRight, withFadeEffect }) => css`
    &::before,
    &::after {
      content: '';
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 40px;
    }

    ${withFadeEffect &&
    css`
      &::after {
        ${isScrollableToRight &&
        css`
          background: linear-gradient(
            90deg,
            transparent 0%,
            ${theme.tokens.colors.surface['surface-page']} 100%,
            ${theme.tokens.colors.surface['surface-page']} 100%
          );
        `}
        right: 0;
      }

      &::before {
        ${isScrollableToLeft &&
        css`
          background: linear-gradient(
            -90deg,
            transparent 0%,
            ${theme.tokens.colors.surface['surface-page']} 100%,
            ${theme.tokens.colors.surface['surface-page']} 100%
          );
        `}
        left: 0;
      }
    `}
  `}
`

export const StyledTabScrollerWrapper = styled.div<{
  leftOffset: number
  rightOffset: number
}>`
  -ms-overflow-style: none;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow-x: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${({ leftOffset, rightOffset }) => css`
    gap: 2px;
    scroll-padding: ${leftOffset}px;

    ${leftOffset > 0 &&
    css`
      &::before {
        content: '';
        scroll-snap-type: x mandatory;
        width: ${leftOffset - 2}px;
      }
    `}

    ${rightOffset > 0 &&
    css`
      &:after {
        content: '';
        scroll-snap-type: x mandatory;
        width: ${rightOffset - 2}px;
      }
    `}
  `}
`
