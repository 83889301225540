import React from 'react'
import { selectAccount } from 'astra-core/containers/AccountProvider'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ERoutes } from 'shared/types/routes'
import { useAppSelector } from 'shared/lib/@reduxjs'
import { useLiveChatIntegration } from 'entities/live-chat'

import { ComponentOfAuthorized, ComponentOfUnauthorized } from './components'
import { CasinoComponentOfAuthorized } from './components/CasinoComponentOfAuthorized'
import {
  StyledPersonalDataContainer,
  StyledWarningAboutHarm
} from './PersonalData.styled'

export const PersonalData = () => {
  useLiveChatIntegration()
  const [t] = useTranslation()
  const account = useAppSelector(selectAccount)
  const casinoMatches = useRouteMatch(ERoutes.Casino)

  return (
    <StyledPersonalDataContainer>
      <StyledWarningAboutHarm to={ERoutes.Warning}>
        {t('warning.link_title')}
      </StyledWarningAboutHarm>

      {account ? (
        casinoMatches ? (
          <CasinoComponentOfAuthorized />
        ) : (
          <ComponentOfAuthorized />
        )
      ) : (
        <ComponentOfUnauthorized />
      )}
    </StyledPersonalDataContainer>
  )
}
